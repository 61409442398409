import React from "react";
import ErrorPage from "../components/ErrorPage";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SocialLinks from "../components/SocialLinks/SocialLinks";

const NotFound = () => {
  return (
    <>
      <MainHeader />
      <SocialLinks />
      <ErrorPage />
      <Footer />
    </>
  );
};

export default NotFound;

import React from "react";
import styled from "styled-components";
// import { categories } from "../data";
import { mobile } from "../responsive";
import CategoryItem from "./CategoryItem";
import img6 from "../assets/promo.jpeg";

const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  /* border: 1px solid red; */
  ${mobile({ padding: "0px", flexDirection: "column" })};
`;
const Categories = () => {
  return (
    <>
      <div style={{ marginTop: "50px" }}>
        <h1 style={{ textAlign: "center" }}>Our Latest Promotion</h1> <br />
        <Container>
          <CategoryItem item={img6} />
          {/* {categories.map((item) => (
            <CategoryItem item={item} key={item.id} />
          ))} */}
        </Container>
      </div>
    </>
  );
};

export default Categories;

import React, { useEffect, useState } from "react";
import { publicRequest } from "../../requestMethod";
import Product from "../Product";
import Flickity from "react-flickity-component";
import "./ProductSlider.css";
import { useNavigate } from "react-router-dom";

const flickityOptions = {
  initialIndex: 2,
};

const ProductSlider = () => {
  const [women, setWomen] = useState(null);
  const [men, setMen] = useState(null);
  const [watches, setWatches] = useState(null);
  const [bags, setBags] = useState(null);
  const [shoes, setShoes] = useState(null);
  const [accessories, setAccessories] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function getWomenProduct() {
      const res = await publicRequest.get(
        `products/new/category/?category=WOMEN`
      );
      setWomen(res.data);
    }
    getWomenProduct();

    async function getMenProduct() {
      const res = await publicRequest.get(
        `products/new/category/?category=MEN`
      );
      setMen(res.data);
    }
    getMenProduct();

    async function getBagsProduct() {
      const res = await publicRequest.get(
        `products/new/category/?category=BAGS`
      );
      setBags(res.data);
    }
    getBagsProduct();

    async function getShoesProduct() {
      const res = await publicRequest.get(
        `products/new/category/?category=SHOES`
      );
      setShoes(res.data);
    }
    getShoesProduct();

    async function getAccessoriesProduct() {
      const res = await publicRequest.get(
        `products/new/category/?category=ACCESSORIES`
      );
      setAccessories(res.data);
    }
    getAccessoriesProduct();

    async function getWatchesProduct() {
      const res = await publicRequest.get(
        `products/new/category/?category=WATCHES`
      );
      setWatches(res.data);
    }
    getWatchesProduct();
  }, []);

  let womensItems = women?.map((product) => (
    <Product item={product} key={product?._id} />
  ));

  let mensItems = men?.map((product) => (
    <Product item={product} key={product?._id} />
  ));

  let bagsItems = bags?.map((product) => (
    <Product item={product} key={product?._id} />
  ));
  let shoesItems = shoes?.map((product) => (
    <Product item={product} key={product?._id} />
  ));
  let accessoriesItems = accessories?.map((product) => (
    <Product item={product} key={product?._id} />
  ));
  let watchesItems = watches?.map((product) => (
    <Product item={product} key={product?._id} />
  ));

  return (
    <>
      <div className="slider-seperator">
        <h1 className="Promo-heading">Latest in Women's Fashion</h1>
        <Flickity options={flickityOptions}>{womensItems}</Flickity>
      </div>

      <div className="viewall-btn-container">
        <button onClick={() => navigate("/products/WOMEN")}>View All</button>
      </div>

      <div className="slider-seperator">
        <h1 className="Promo-heading">Latest in Men's Fashion</h1>
        <Flickity options={flickityOptions}>{mensItems}</Flickity>
      </div>
      <div className="viewall-btn-container">
        <button onClick={() => navigate("/products/MEN")}>View All</button>
      </div>
      <div className="slider-seperator">
        <h1 className="Promo-heading">Latest in Bags's Fashion</h1>
        <Flickity options={flickityOptions}>{bagsItems}</Flickity>
      </div>
      <div className="viewall-btn-container">
        <button onClick={() => navigate("/products/BAGS")}>View All</button>
      </div>
      <div className="slider-seperator">
        <h1 className="Promo-heading">Latest in Shoes's Fashion</h1>
        <Flickity options={flickityOptions}>{shoesItems}</Flickity>
      </div>
      <div className="viewall-btn-container">
        <button onClick={() => navigate("/products/SHOES")}>View All</button>
      </div>
      <div className="slider-seperator">
        <h1 className="Promo-heading">Latest in Watches's Fashion</h1>
        <Flickity options={flickityOptions}>{watchesItems}</Flickity>
      </div>
      <div className="viewall-btn-container">
        <button onClick={() => navigate("/products/WATCHES")}>View All</button>
      </div>
      <div className="slider-seperator">
        <h1 className="Promo-heading">Latest in Accessories's Fashion</h1>
        <Flickity options={flickityOptions}>{accessoriesItems}</Flickity>
      </div>
      <div className="viewall-btn-container">
        <button onClick={() => navigate("/products/ACCESSORIES")}>
          View All
        </button>
      </div>
    </>
  );
};

export default ProductSlider;

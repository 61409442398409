import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/logo.png";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-item: center;
`;
const Wrapper = styled.div`
  text-align: center;
`;

const ErrorPage = () => {
  return (
    <Container>
      <Wrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="error-template">
                <Link to="/">
                  <img
                    src={logo}
                    alt=""
                    style={{ height: "105px", width: "100px" }}
                  />
                </Link>
                <h1 style={{ fontSize: "40px", color:"#CAA473" }}>Oops!</h1>
                <h2 style={{ fontSize: "50px", color:"red" }}>404 Not Found</h2>
                <div className="error-details" style={{ fontSize: "20px", color:"#CAA473" }}>
                  Sorry, an error has occured, Requested page not found!
                </div>
                <div className="error-actions mt-2">
                  <a href="/" className="btn btn-dark btn-lg">
                    <span className="glyphicon glyphicon-home"></span>
                    Take Me Home{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default ErrorPage;

import React from "react";
import MainHeader from "../../components/MainHeader";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import AllBlogs from "./AllBlogs";

const Blogs = () => {
  return (
    <>
      <MainHeader />
      <AllBlogs />
      <Newsletter />
      <Footer />
    </>
  );
};

export default Blogs;

import React from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import styled from "styled-components";
const Container = styled.div`
  /* text-align: center; */
  margin-top: -50px;
  margin-left: 10px;
  margin-right: 10px;
  /* justify-content: center;
  align-items: center; */
`;
const TermsAndConditions = () => {
  return (
    <>
      <MainHeader />
      <Container>
        <h1>TERMS & CONDITIONS</h1>
        <br />
        <br />
        <h4>WELCOME TO OUR TERMS AND CONDITIONS OF SALE AND DELIVERY.</h4>
        <p>
          THESE TERMS AND CONDITIONS APPLY TO CUSTOMERS LOCATED IN THE UK
          (“CUSTOMERS”) WHO ACCESS THE FABRIC DESIGNER OUTLET WEBSITE
          (“WEBSITE”). PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE
          ACCESSING AND/OR ORDERING ANY GOODS FROM THE WEBSITE.
        </p>
        <p>
          THESE ARE THE TERMS AND CONDITIONS OF FABRIC DESIGNER OUTLET, WHICH IS
          THE COMPANY THAT SELLS GOODS TO CUSTOMERS ON THE WEBSITE. IF YOU
          ACCESS THE WEBSITE, AND/OR PLACE AN ORDER FOR GOODS, YOU AGREE TO BE
          BOUND BY THESE TERMS AND CONDITIONS.
        </p>
        <p>
          YOU SHOULD PRINT A COPY OF THESE TERMS AND CONDITIONS FOR FUTURE
          REFERENCE.
        </p>
        <p>
          THE WEBSITE IS OPERATED BY FABRIC DESIGNER OUTLET, WHOSE REGISTERED
          ADDRESS IS XXXX.
        </p>
        <p>
          IF YOU HAVE ANY QUESTIONS ABOUT THE WEBSITE PLEASE CONTACT CUSTOMER
          SERVICES.
        </p>
        <h4>HOW TO ORDER</h4>
        <ol start="1">
          <li>
            SELECT THE GOODS YOU REQUIRE FROM THE WEBSITE AND CLICK ‘ADD TO BAG’
          </li>
          <li>
            CHOOSE THE QUANTITY OF GOODS YOU WISH TO PURCHASE FROM FABRIC
            DESIGNER OUTLET (THE DEFAULT WILL BE ONE (1) UNIT). PLEASE NOTE THAT
            A LIMIT IS SET TO NINETY-NINE (99) UNITS PER ITEM. TO ORDER
            QUANTITIES GREATER THAN 99 UNITS PER ITEM, PLEASE CONTACT CUSTOMER
            SERVICES WHO WILL BE ABLE TO ASSIST YOU WITH YOUR ORDER
          </li>
          <li>
            REVIEW YOUR BAG AND MAKE SURE ALL SPECIFICATIONS SUCH AS SIZE (SEE
            OUR SIZING CHART) AND COLOUR FIT YOUR REQUIREMENTS, THEN CLICK
            “SECURE CHECKOUT”
          </li>
          <li>
            IF YOU ARE A NEW USER, YOU WILL NEED TO ENTER AN EMAIL ADDRESS AND
            CREATE A USERNAME AND PASSWORD. YOU MUST KEEP YOUR USERNAME AND
            PASSWORD CONFIDENTIAL AS THEY ALLOW ACCESS TO YOUR ACCOUNT. TO
            CONTINUE SETTING UP AN ACCOUNT YOU WILL NEED TO ENTER YOUR PERSONAL
            DETAILS, BILLING ADDRESS (WHICH MUST CORRESPOND WITH YOUR PAYMENT
            CARD ADDRESS) AND DELIVERY ADDRESS
          </li>
          <li>
            ONCE YOU HAVE SET UP AN ACCOUNT, YOU WILL BE DIRECTED TO THE
            SHIPPING CONFIRMATION AND DELIVERY OPTIONS PAGE WHERE YOU MAY ENTER
            VOUCHER CODES, SELECT THE TYPE OF DELIVERY, AND VARY DELIVERY AND/OR
            BILLING ADDRESSES.
          </li>
          <li>
            YOU WILL THEN PROCEED TO THE SECURE PAYMENT PAGE WHERE YOU MAY
            SELECT THE PAYMENT TYPE AND COMPLETE YOUR ORDER. YOUR PAYMENT WILL
            BE COLLECTED FROM YOU BY FABRIC ONLINE LIMITED. FOLLOWING SUBMISSION
            OF YOUR ORDER YOU WILL BE SENT AN AUTOMATED ORDER ACKNOWLEDGEMENT TO
            THE EMAIL ADDRESS PROVIDED.
          </li>
        </ol>
        <p>
          BY PLACING AN ORDER THROUGH THE WEBSITE, YOU WARRANT THAT YOU ARE
          LEGALLY CAPABLE OF ENTERING INTO BINDING CONTRACTS.
        </p>
        <h4>
          HOW THE CONTRACT FOR THE GOODS IS FORMED BETWEEN YOU AND FABRIC
          DESIGNER OUTLET
        </h4>
        <p>
          AFTER PLACING YOUR ORDER, YOU WILL RECEIVE AN AUTOMATED ORDER
          ACKNOWLEDGEMENT. PLEASE NOTE THAT THIS DOES NOT MEAN THAT YOUR ORDER
          HAS BEEN ACCEPTED. ALL ORDERS ARE SUBJECT TO ACCEPTANCE BY FABRIC
          DESIGNER OUTLET.
        </p>
        <p>
          YOU WILL RECEIVE AN EMAIL CONFIRMING THAT FABRIC DESIGNER OUTLET HAS
          ACCEPTED YOUR ORDER WHEN YOUR ORDER HAS BEEN PROCESSED AND IS READY
          FOR DELIVERY BY FABRIC DESIGNER OUTLET.
        </p>
        <p>
          ONCE FABRIC DESIGNER OUTLET YOUR ORDER, A CONTRACT TO PURCHASE THE
          GOODS FROM FABRIC DESIGNER OUTLET TAKES EFFECT BETWEEN.
        </p>
        <p>
          THE CONTRACT TO SELL GOODS TO YOU ONLY RELATES TO THOSE GOODS LISTED
          IN THE CONFIRMATION EMAIL.
        </p>
        <p>
          HOWEVER, UPON RECEIPT OF YOUR ORDER, FABRIC DESIGNER OUTLET WILL
          CREATE AN AUTHORISATION. THIS WILL SHOW ON YOUR BANK STATEMENT AS A
          RESERVATION OF FUNDS WHICH ALLOCATES THE MONEY TO YOUR ORDER, BUT THIS
          WILL NOT BE REALISED UNTIL YOUR ORDER HAS BEEN PICKED AND PROCESSED IN
          FABRIC DESIGNER OUTLET WAREHOUSE. IN THE EVENT OF US NOT BEING ABLE TO
          FULFIL YOUR ORDER WE WILL IMMEDIATELY REQUEST THAT YOUR BANK CANCELS
          THE AUTHORISATION. IF THE AUTHORISATION STILL REMAINS AFTER SEVERAL
          DAYS, PLEASE CONTACT YOUR BANK TO REQUEST THAT THEY ACT UPON OUR
          CANCELLATION OF THE PAYMENT.
        </p>
        <h4>OUR GOODS</h4>
        <p>
          A DESCRIPTION OF THE MAIN CHARACTERISTICS OF GOODS CAN BE FOUND ON THE
          PRODUCT PAGES OF THE WEBSITE. PLEASE BE ADVISED, THERE ARE A FEW
          CATEGORIES WHERE ITEMS ARE SUBJECT TO
        </p>
        <ol start="1">
          <li>PREMIUM GRADE:</li>
          <p>
            THIS ITEMS ARE 99% LOYAL TO ORIGINAL DESIGN AND ARE FULLY PACKED
            WITH WHICHEVER ORIGINAL PACKING MAY INCLUDE.
          </p>
          <li>A+ GRADE:</li>
          <p>
            THIS ITEMS ARE 95% LOYAL TO ORIGINAL DESIGN AND IT MAY AND MAY NOT
            INCLUDE PACKAGING. PLEASE BE ADVISED THERE IS NO REFUND ON ANY ITEM
            AFTER DELIVERY, A FUTURE SPENDING CREDIT OR REPLACEMENT ITEM WILL BE
            ISSUED WHEN LOCATION OF GOODS IS ABSOLUTE AND DELIVERY IS NOT
            POSSIBLE.
          </p>
        </ol>
        <h4>DELIVERY:</h4>
        <p>
          SHIPPING IS ABOUT 10 WORKING DAYS BUT THERE HAVE BEEN DELAYS RECENTLY
          BECAUSE OF THE CURRENT SITUATION SO IT CAN TAKE UP TO 21 DAYS. HOWEVER
          ABOUT %90 OF THE TIME, YOU WILL RECEIVE IT WITHIN 10 DAYS. THERE ARE
          UNIQUE CASES WHERE SHIPPING CAN TAKE UP TO 7 WEEKS WHICH RECEIVER
          NEEDS TO WAIT.
        </p>
        <h4>SIZING:</h4>
        <p>
          WE ARE AWARE THERE MIGHT BE SOME DIFFERENCES BETWEEN BRANDS FITTING
          AND PRESENTATION.
        </p>
        <p>
          ALSO THERE MIGHT BE SOME DIFFERENCE BETWEEN UK AND TURKEY SIZING,
          ALTHOUGH %90 OUT OF THE TIME WE WILL SEND YOU REQUESTED SIZES, HOWEVER
          OCCASIONALLY WE WILL SEND BIGGER OR SMALLER SIZE DEPENDING ON PRODUCT
          FITTING.
        </p>
        <h4>DAMAGED GOODS:</h4>
        <p>A REPORT SHOULD BE MADE IMMEDIATELY UPON ARRIVAL.</p>
        <p>
          FABRIC DESIGNER OUTLET WILL NOT TAKE ANY RESPONSIBILITY AFTER 15 DAYS
          OF ITEM DELIVERY DATE AND USED ITEMS.
        </p>
        <p>
          PRICES FOR GOODS ON THE WEBSITE APPEAR ON THE PRODUCT PAGES AND
          INCLUDE ALL APPLICABLE TAXES.
        </p>
        <p>
          PRICES AND OFFERS MAY VARY AND MAY ONLY BE AVAILABLE FOR LIMITED
          PERIODS. ALL PRICES AND OFFERS ARE SUBJECT TO AVAILABILITY AND MAY BE
          WITHDRAWN OR AMENDED AT OUR DISCRETION. THE PRICES ON THE WEBSITE MAY
          DIFFER TO PRICES IN OUR RETAIL STORES, CATALOGUES OR ELSEWHERE.
        </p>
        <p>
          THE WEBSITE CONTAINS A LARGE NUMBER OF GOODS AND IT IS POSSIBLE THAT,
          DESPITE OUR BEST EFFORTS, SOME OF THE GOODS LISTED ON THE WEBSITE MAY
          BE INCORRECTLY PRICED. IF WE DISCOVER AN ERROR IN THE PRICE OF THE
          GOODS YOU HAVE ORDERED WE WILL INFORM YOU AS SOON AS POSSIBLE. WE WILL
          GIVE YOU THE OPTION OF RECONFIRMING YOUR ORDER AT THE CORRECT PRICE OR
          CANCELLING IT.
        </p>
        <p>
          FABRIC DESIGNER OUTLET IS UNDER NO OBLIGATION TO SELL INCORRECTLY
          PRICED GOODS TO YOU AT THE INCORRECT PRICE.
        </p>
        <p>
          SOME OFFERS CARRY A MAXIMUM QUANTITY PER CUSTOMER. IF THIS IS
          APPLICABLE, THIS IS DETAILED ON THE INDIVIDUAL OFFER.
        </p>
        <h4>EX DISPLAY</h4>
        <p>
          ANY GOODS DISPLAYING “EX DISPLAY” ON THE IMAGE ARE HEAVILY MARKED DOWN
          IN PRICE TO CLEAR. THEY MAY HAVE BEEN A DISPLAY MODEL IN ONE OF OUR
          STORES AND AS SUCH MAY SHOW SIGNS OF DISCOLOURATION, SLIGHT MARKS AND
          EVIDENCE OF PRIOR FITTING. FAULTS ARE NOT OF A SERIOUS OR STRUCTURAL
          NATURE AND ARE USUALLY MINOR MARK
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndConditions;

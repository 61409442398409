import styled from "styled-components";
// import Navbar from "../components/Navbar";
// import Announcement from "../components/Announcement";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
// import { mobile } from "../responsive";
import Menu from "../components/Menu/Menu";
import MainHeader from "../components/MainHeader";
import { useLocation } from "react-router-dom";
import SocialLinks from "../components/SocialLinks/SocialLinks";

const Container = styled.div`
  margin-top: -175px;
`;
 
const Title = styled.h1`
  margin: 20px;
`;

// const FilterContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const Filter = styled.div`
//   margin: 20px;
//   ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
// `;

// const FilterText = styled.span`
//   font-size: 20px;
//   font-weight: 600;
//   margin-right: 20px;
//   ${mobile({ marginRight: "0px" })}
// `;

// const Select = styled.select`
//   padding: 10px;
//   margin-right: 20px;
//   ${mobile({ margin: "10px 0px" })}
// `;
// const Option = styled.option``;

const ProductSearchList = () => {
  const location = useLocation();
  const search = location.pathname.split("/")[3];
  return (
    <Container>
      {/* <Announcement /> */}
      <MainHeader />
      {/* <Navbar /> */}
      <Menu />
      <SocialLinks />
      <Title></Title>
      {/* <FilterContainer>
        <Filter>
          <FilterText>Filter Products:</FilterText>
          <Select>
            <Option disabled selected>
              Color
            </Option>
            <Option>White</Option>
            <Option>Black</Option>
            <Option>Red</Option>
            <Option>Blue</Option>
            <Option>Yellow</Option>
            <Option>Green</Option>
          </Select>
          <Select>
            <Option disabled selected>
              Size
            </Option>
            <Option>XS</Option>
            <Option>S</Option>
            <Option>M</Option>
            <Option>L</Option>
            <Option>XL</Option>
          </Select>
        </Filter>
        <Filter>
          <FilterText>Sort Products:</FilterText>
          <Select>
            <Option selected>Newest</Option>
            <Option>Price (asc)</Option>
            <Option>Price (desc)</Option>
          </Select>
        </Filter>
      </FilterContainer> */}
      <Products search={search}/>
      <Newsletter />
      <Footer />
    </Container>
  );
};

export default ProductSearchList;
import React, { useEffect } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import MainHeader from "../components/MainHeader";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { publicRequest } from "../requestMethod";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/apiCalls";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  margin-top: -175px;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://images.pexels.com/photos/6984661/pexels-photo-6984661.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  padding: 20px;
  width: 40%;
  margin-top: 10%;
  background-color: #fff;
  border-radius: 10px;
  ${mobile({ width: "75%" })};
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`;
const Agreement = styled.span`
  font-size: 12px;
  margin: 10px 0px;
`;
const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: #171717;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
`;

const Register = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const handleClick = async (e) => {
    e.preventDefault();
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    // const phone_regex =/^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (first_name === "") {
      toast.error("Please Enter First Name");
    } else if (first_name.length <= 2) {
      toast.error("Please Enter A Valid First Name");
    } else if (last_name === "") {
      toast.error("Please Enter Last Name");
    } else if (last_name.length <= 2) {
      toast.error("Please Enter A Valid Last Name");
    } else if (username === "") {
      toast.error("Please Enter Username");
    } else if (username.length < 5) {
      toast.error("Please Enter A Valid Username");
    } else if (email === "") {
      toast.error("Please Enter Email");
    } else if (!regEx.test(email)) {
      toast.error("Please Enter A Valid Email");
    } else if (phone === "") {
      toast.error("Please Enter Phone Number");
    }
    //  else if (phone.length !== 12) {
    //   toast.error("Please Enter A Valid Phone Number Like +440000000000");
    // } 
    else if (password === "") {
      toast.error("Please Enter Password");
    } 
    // else if (password.length < 8) {
    //   toast.error("Please Enter Password Of Length 8 Characters");
    // } 
    else if (confirm_password === "") {
      toast.error("Please Enter Confirm Password");
    } else if (confirm_password !== password) {
      toast.error("Password & Confirm Password Doesn't Match");
    } else {
      const data = {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone.toString(),
        password: password,
      };
      try {
        const response = await publicRequest.post("auth/register", data);
        if (response.status === 201) {
          login(dispatch, { username, password });
          toast.success("Registered Successful");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } catch (error) {
        toast.error("Username Or Email Or Phone Number Already Exist");
      }
    }
  };
  useEffect(() => {
    if (user?.currentUser !== null) {
      navigate("/");
    }
  }, [user?.currentUser, navigate]);
  return (
    <>
      <MainHeader />
      <Container>
        <Wrapper>
          <Title>CREATE AN ACCOUNT</Title>
          <Form>
            <Input
              placeholder="First Name"
              type="text"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              placeholder="Last Name"
              type="text"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
            <Input
              placeholder="Username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              placeholder="Email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="Phone"
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Input
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              placeholder="Confirm Password"
              type="password"
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Agreement>
              By creating an account, I consent to the processing of my personal
              data in accordance with the <b>PRIVACY POLICY</b>
            </Agreement>
            <Button onClick={handleClick}>CREATE</Button>
          </Form>
        </Wrapper>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Register;

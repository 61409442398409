import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Product from "./Product";
import { useLocation } from "react-router-dom";
import PreLoader from "./PreLoader/PreLoader";
import ReactPaginate from "react-paginate";
import { publicRequest } from "../requestMethod";
import ErrorPage from "./ErrorPage";
import { mobile } from "../responsive";
import { man, women, bags, accessories, shoes, watches, kids } from "../data";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ display: "block" })}
`;
const Filter = styled.div`
  margin: 20px;
  /* margin-top: 50px; */
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  ${mobile({ marginRight: "0px" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  ${mobile({ margin: "10px 0px" })}
`;
const Option = styled.option``;
const Products = (props) => {
  const [product, setProduct] = useState(null);
  const [apical, SetApiCall] = useState(true);
  const [allProduct, setAllProduct] = useState(null);
  const [showfilter, setShowFilter] = useState(null);

  const defaultSelectValue = "ALL";
  const [selected, setSelected] = useState(defaultSelectValue);
  const [selectedsubcat, setSelectedSubCat] = useState(defaultSelectValue);
  const location = useLocation();
  useEffect(() => {
    setShowFilter(location?.pathname?.split("/")[2]);
    SetApiCall(true);
    if (props.sub_category !== undefined) {
      const subCategory = props?.sub_category?.replace("%20", " ");
      const getProductSubCategory = async () => {
        try {
          const res = await publicRequest.get(
            `products/subcategory/?sub_category=${subCategory}`
          );
          setProduct(res.data);
          setAllProduct(res.data);
          SetApiCall(false);
        } catch (err) {
          SetApiCall(false);
        }
      };
      getProductSubCategory();
    } else if (props.filter !== undefined) {
      const getProductCategory = async () => {
        try {
          const res = await publicRequest.get(
            `products/category/?category=${props.filter}`
          );
          setProduct(res.data);
          setAllProduct(res.data);
          SetApiCall(false);
        } catch (err) {
          SetApiCall(false);
        }
      };
      getProductCategory();
    } else if (props.search !== undefined) {
      const getProductCategory = async () => {
        try {
          const res = await publicRequest.get(
            `products/productname/?productname=${props.search}`
          );
          setProduct(res.data);
          setAllProduct(res.data);
          SetApiCall(false);
        } catch (err) {
          SetApiCall(false);
        }
      };
      getProductCategory();
    } else {
      const getProductCategory = async () => {
        try {
          const res = await publicRequest.get(`products?new=true`);
          setProduct(res.data);
          setAllProduct(res.data);
          SetApiCall(false);
        } catch (err) {
          SetApiCall(false);
        }
      };
      getProductCategory();
    }
  }, [props.filter, props.sub_category, props.search, location?.pathname]);

  // useEffect(() => {
  //   if (props.sort === "newest") {
  //     setFilteredProducts((prev) =>
  //       [...prev].sort((a, b) => a.createdAt - b.createdAt)
  //     );
  //   } else if (props.sort === "asc") {
  //     setFilteredProducts((prev) =>
  //       [...prev].sort((a, b) => a.price - b.price)
  //     );
  //   } else {
  //     setFilteredProducts((prev) =>
  //       [...prev].sort((a, b) => b.price - a.price)
  //     );
  //   }
  // }, [props.sort]);
  function Items({ currentItems }) {
    return (
      <>
        <Container>
          {currentItems &&
            currentItems.map((item) => (
              // <Link
              //   to={`/product/${item._id}`}
              //   style={{ textDecoration: "none" }}
              //   key={item.old_id}
              // >
              <Product item={item} key={item._id} />
              // </Link>
            ))}
        </Container>
      </>
    );
  }
  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = product?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(product?.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % product?.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
    };
    return (
      <>
        {showfilter === "search" ? (
          <FilterContainer>
            <Filter>
              <FilterText>Category Search:</FilterText>
              <Select
                onChange={(e) => {
                  setSelected(e.target.value);
                  const cat = e.target.value;
                  if (cat) {
                    if (cat !== "ALL") {
                      const filter_prod = allProduct?.filter(
                        (categ) => categ.category === cat
                      );
                      const uniqueArray = filter_prod.filter((value, index) => {
                        const _value = JSON.stringify(value);
                        return (
                          index ===
                          filter_prod.findIndex((obj) => {
                            return JSON.stringify(obj) === _value;
                          })
                        );
                      });
                      // console.log(uniqueArray);
                      setProduct(uniqueArray);
                    } else {
                      setProduct(allProduct);
                    }
                  }
                }}
                defaultValue={selected}
              >
                <Option>{selected}</Option>
                <Option>ALL</Option>
                <Option>MEN</Option>
                <Option>WOMEN</Option>
                <Option>ACCESSORIES</Option>
                <Option>BAGS</Option>
                <Option>SHOES</Option>
                <Option>WATCHES</Option>
                <Option>KIDS</Option>
              </Select>
            </Filter>
            <Filter>
              <FilterText>Subcategoy Search:</FilterText>
              <Select
                onChange={(e) => {
                  setSelectedSubCat(e.target.value);
                  const cat = e.target.value;
                  if (cat) {
                    if (cat !== "All Sub Category") {
                      const filter_prod = allProduct?.filter(
                        (sub) => sub.sub_category === cat
                      );
                      const uniqueArray = filter_prod.filter((value, index) => {
                        const _value = JSON.stringify(value);
                        return (
                          index ===
                          filter_prod.findIndex((obj) => {
                            return JSON.stringify(obj) === _value;
                          })
                        );
                      });
                      setProduct(uniqueArray);
                    } else {
                      setProduct(allProduct);
                    }
                  }
                }}
                defaultValue={selectedsubcat}
              >
                <Option selected>All Sub Category</Option>
                {selected === "MEN" ? (
                  man.map((subcat) => (
                    <Option key={subcat.index}>{subcat.address}</Option>
                  ))
                ) : selected === "WOMEN" ? (
                  women.map((subcat) => (
                    <>
                      <Option key={subcat.index}>{subcat.address}</Option>
                    </>
                  ))
                ) : selected === "ACCESSORIES" ? (
                  accessories.map((subcat) => (
                    <Option key={subcat.index}>{subcat.address}</Option>
                  ))
                ) : selected === "BAGS" ? (
                  bags.map((subcat) => (
                    <Option key={subcat.index}>{subcat.address}</Option>
                  ))
                ) : selected === "SHOES" ? (
                  shoes.map((subcat) => (
                    <Option key={subcat.index}>{subcat.address}</Option>
                  ))
                ) : selected === "WATCHES" ? (
                  watches.map((subcat) => (
                    <Option key={subcat.index}>{subcat.address}</Option>
                  ))
                ) : selected === "KIDS" ? (
                  kids.map((subcat) => (
                    <Option key={subcat.index}>{subcat.address}</Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Filter>
          </FilterContainer>
        ) : (
          <></>
        )}
        {currentItems?.length !== 0 ? (
          <>
            <Items currentItems={currentItems} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        ) : (
          <ErrorPage />
        )}
      </>
    );
  }

  return (
    <>
      {product === null ? (
        <PreLoader text="Loading Products" />
      ) : apical ? (
        <PreLoader text="Loading Products" />
      ) : (
        <PaginatedItems itemsPerPage={12} />
      )}
    </>
  );
};

export default Products;

import men from "../../assets/catCard/men.jpeg";
import women from "../../assets/catCard/women.jpeg";
import accessories from "../../assets/catCard/accessories.jpeg";
import watch from "../../assets/catCard/watch.jpeg";
import bag from "../../assets/catCard/bags.jpeg";
import shoes from "../../assets/catCard/shoes.jpeg";

export const categ = [
  {
    text: "SHOP MEN",
    img: men,
    url: "/products/MEN",
  },
  {
    text: "SHOP WOMEN",
    img: women,
    url: "/products/WOMEN",
  },
  {
    text: "SHOP BAGS",
    img: bag,
    url: "/products/BAGS",
  },
  {
    text: "SHOP ACCESSORIES",
    img: accessories,
    url: "/products/ACCESSORIES",
  },
  {
    text: "SHOP SHOES",
    img: shoes,
    url: "/products/SHOES",
  },
  {
    text: "SHOP WATCHES",
    img: watch,
    url: "/products/WATCHES",
  },
];

import React from "react";
// import styled from "styled-components";
// import { Link } from "react-router-dom";

import img from "../assets/promo.jpeg";
// import img1 from "../assets/promo2.jpeg";

// const Button = styled.button`
//   border: none;
//   padding: 10px;
//   background-color: white;
//   color: gray;
//   cursor: pointer;
//   font-weight: 600;
//   border-radius: 5px;
// `;

const MobileSlider = () => {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      // className="carousel slide carousel-fade"
      // data-bs-ride="false"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators" style={{height:'70vh'}}>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        {/* <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button> */}
      </div>
      <div className="carousel-inner">
      <div className="carousel-item active" data-bs-interval="3500" style={{height:'70vh'}}>
            <img
              src={img}
              className="d-block w-100 h-100"
              alt="..."
            />
            <div
              className="carousel-caption d-md-block"
              style={{ paddingBottom: "30px" }}
            >
            </div>
        </div>
      {/* <div className="carousel-item" data-bs-interval="3500" style={{height:'70vh'}}>
            <img
              src={img1}
              className="d-block w-100 h-100"
              alt="..."
            />
            <div
              className="carousel-caption d-md-block"
              style={{ paddingBottom: "30px" }}
            >
            </div>
        </div> */}
      </div>
      {/* <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button> */}
    </div>
  );
};

export default MobileSlider;

import React, { useEffect, useState } from "react";
import MainHeader from "../../components/MainHeader";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { IMG_URL, publicRequest } from "../../requestMethod";
import { useLocation } from "react-router-dom";
import "./Blog.css";
import PreLoader from "../../components/PreLoader/PreLoader";
import ReactHtmlParser from "react-html-parser";

const SingleBlog = () => {
  const [blog, setBlog] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const slug = location?.pathname?.split("/")[2];
    const getAllBlogs = async () => {
      const res = await publicRequest.get(`blog/slug/?slug=${slug}`);
      console.log(res.data);
      setBlog(res.data);
    };
    getAllBlogs();
  }, [location?.pathname]);
  return (
    <>
      {blog === null ? (
        <PreLoader />
      ) : (
        <div className="singel-blog">
          <MainHeader />
          <div className="single-blog-container">
            <h1 className="single-blog-heading">{blog.title}</h1>
            <img
              src={`${IMG_URL}${blog?.image?.[0]?.url}`}
              alt="blogimg"
              className="single-blog-image"
            />
            <p className="single-blog-detail">
              {ReactHtmlParser(blog.description)}
            </p>
          </div>
          <Newsletter />
          <Footer />
        </div>
      )}
    </>
  );
};

export default SingleBlog;

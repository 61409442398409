import React from "react";
import { IMG_URL } from "../../requestMethod";
import styled from "styled-components";
import { mobile } from "../../responsive";
import { useNavigate } from "react-router-dom";

const RelatedProducts = (relatedProducts) => {
  const navigate = useNavigate();

  const Container = styled.div`
    flex: 1;
    margin: 5px;
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    ${mobile({
      backgroundColor: "#eff3f7",
      minWidth: "150px",
      height: "250px",
      marginTop: "5px",
    })};
  `;
  const Image = styled.img`
    height: 75%;
    width: 80%;
    object-fit: "contain";
    z-index: 2;
    border-radius: 4px;
    ${mobile({
      marginTop: "20px",
      height: "60%",
      width: "90%",
      objectFit: "cover",
    })};
  `;
  const InfoConatiner = styled.div`
    text-align: center;
    margin-top: 5px;
    ${mobile({ marginTop: "10px", height: "30%" })};
  `;
  const ProductTitle = styled.h1`
    font-size: 12px;
    font-weight: 600;
    ${mobile({ fontSize: "12px", marginBottom: "-10px" })};
  `;
  const PriceContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    ${mobile({
      fontSize: "8px",
      marginTop: "-10px",
    })}
  `;
  return (
    <>
      <h2 className="text-center mb-3">You may also like</h2>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2">
          {relatedProducts?.relatedProduct?.data?.map((product, index) => (
            <>
              <Container
                key={index}
                onClick={() => navigate(`/product/${product?.slug}`)}
              >
                <Image src={`${IMG_URL}${product?.images[0]?.guid}`} />
                <InfoConatiner>
                  <ProductTitle>{product.post_title}</ProductTitle>
                  <PriceContainer>
                    <span
                      style={{
                        fontSize: "12px",
                        margin: "10px 0px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      £ {Math.floor(product.max_price)}
                    </span>
                  </PriceContainer>
                </InfoConatiner>
              </Container>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default RelatedProducts;

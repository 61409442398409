import styled from "styled-components";
// import Navbar from "../components/Navbar";
// import Announcement from "../components/Announcement";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
// import { mobile } from "../responsive";
import Menu from "../components/Menu/Menu";
import MainHeader from "../components/MainHeader";
// import { useLocation } from "react-router-dom";
// import { useState } from "react";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  margin-top: -175px;
`;

const Title = styled.h1`
  margin: 20px;
`;

// const FilterContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const Filter = styled.div`
//   margin: 20px;
//   ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
// `;

// const FilterText = styled.span`
//   font-size: 20px;
//   font-weight: 600;
//   margin-right: 20px;
//   ${mobile({ marginRight: "0px" })}
// `;

// const Select = styled.select`
//   padding: 10px;
//   margin-right: 20px;
//   ${mobile({ margin: "10px 0px" })}
// `;
// const Option = styled.option``;

const ProductList = () => {
  const location = useLocation();
  const filter = location.pathname.split("/")[2];
  // const [subFilter, setSubFilter] = useState({});
  // const [sort, setSort] = useState("newest");

  // const handleFilters = (e) => {
  //   const value = e.target.value;
  //   setSubFilter({
  //     ...subFilter,
  //     [e.target.name]: value,
  //   });
  // };

  return (
    <Container>
      <SocialLinks />
      {/* <Announcement /> */}
      <MainHeader />
      {/* <Navbar /> */}
      <Menu />
      <Title></Title>
      {/* <FilterContainer>
        <Filter>
          <FilterText>Filter Products:</FilterText>
          <Select name="color" onChange={handleFilters}>
            <Option disabled>Color</Option>
            <Option>White</Option>
            <Option>Black</Option>
            <Option>Red</Option>
            <Option>Blue</Option>
            <Option>Yellow</Option>
            <Option>Green</Option>
          </Select>
          <Select name="size" onChange={handleFilters}>
            <Option disabled>Size</Option>
            <Option>XS</Option>
            <Option>S</Option>
            <Option>M</Option>
            <Option>L</Option>
            <Option>XL</Option>
          </Select>
        </Filter>
        <Filter>
          <FilterText>Sort Products:</FilterText>
          <Select onChange={(e) => setSort(e.target.value)}>
            <Option value="newest">Newest</Option>
            <Option value="asc">Price (asc)</Option>
            <Option value="desc">Price (desc)</Option>
          </Select>
        </Filter>
      </FilterContainer> */}
      {/* <Products filter={filter} sort={sort} subFilter={subFilter} /> */}
      <Products filter={filter} />
      <Newsletter />
      <Footer />
    </Container>
  );
};

export default ProductList;

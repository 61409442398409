// import {
//   FavoriteBorderOutlined,
//   SearchOutlined,
//   ShoppingCartOutlined,
// } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { IMG_URL } from "../requestMethod";
// import ProductQuickView from "./ProductQuickView";
import { useNavigate } from "react-router-dom";
const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
`;
const Container = styled.div`
  flex: 1;
  margin: 5px;
  width: 300px;
  background-color: #f3f3f3;
  /* max-width: 300px; */
  height: 350px;
  display: flex;
  /* margin-left: 7px; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* border: 1px solid red; */
  position: relative;
  ${mobile({
    // minWidth: "130px",
    backgroundColor: "#eff3f7",
    width: "160px",
    // width: "130%",
    height: "370px",
    marginTop: "5px",
    // border: "1px solid red",
  })};
  /* FOR SHOWING ICONS */
  /* &:hover ${Info} {
    opacity: 1;
  } */
`;
const Circle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  /* background-color: white; */
  position: absolute;
`;
const Image = styled.img`
  height: 65%;
  width: 100%;
  object-fit: "cover";
  z-index: 2;
  cursor: pointer;
  border-radius: 4px;
  ${mobile({
    // height: "50%", marginTop: "20px"
    marginTop: "20px",
    height: "60%",
    width: "90%",
    objectFit: "cover",
  })};
`;
// const Icon = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 10px;
//   transition: all 0.5s ease;
//   ${mobile({ height: "30px" })};

//   &:hover {
//     background-color: #e9f5f5;
//     transform: scale(1.1);
//   }
// `;
const InfoConatiner = styled.div`
  text-align: center;
  margin-top: 5px;
  ${mobile({ marginTop: "10px", height: "30%" })};
`;
const ProductTitle = styled.h1`
  font-size: 12px;
  font-weight: 600;
  z-index: 8;
  ${mobile({ fontSize: "12px", marginBottom: "-10px" })};
`;
const ProductDesc = styled.span`
  text-align: center;
  font-size: 10px;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 200px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  ${mobile({ fontSize: "11px", maxWidth: "70px", marginTop: "10px" })};
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  /* text-align: start; */
  ${mobile({
    fontSize: "8px",
    marginTop: "-10px",
  })}/* justify-content: space-evenly; */
`;
// const OverViewBtn = styled.button`
//   width: 240px;
//   height: 30px;
//   margin-bottom: 10px;
//   z-index: 10;
//   border: 1px solid black;
//   border-radius: 4px;
//   cursor: pointer;
//   ${mobile({
//     backgroundColor: "#EFF3F7",
//     width: "120px",
//   })}
// `;
const Product = ({ item }) => {
  // const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Circle />
        <Image
          src={`${IMG_URL}${item?.images[0]?.guid}`}
          onClick={() => navigate(`/product/${item.slug}`)}
        />
        {/* <Info>
          <Icon>
            <ShoppingCartOutlined />
          </Icon>
          <Icon>
            <SearchOutlined />
          </Icon>
          <Icon>
            <FavoriteBorderOutlined />
          </Icon>
        </Info> */}
        {/* <Link to={`/product/${item._id}`} style={{ textDecoration: "none" }}> */}
        <InfoConatiner>
          <ProductTitle>{item.post_title}</ProductTitle>
          <ProductDesc>{item.post_name}</ProductDesc>
          <PriceContainer>
            <span
              style={{
                fontSize: "12px",
                margin: "10px 0px",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              {/* £ {item.max_price.substr(0, item.max_price.length -2)} */}£{" "}
              {Math.floor(item.max_price)}
            </span>
            {/* <span>-40%</span> */}
            {/* <span>206 €</span> */}
          </PriceContainer>
          {/* <OverViewBtn
            onClick={() => {
              setShowModal(true);
            }}
          >
            Quick View
          </OverViewBtn> */}
        </InfoConatiner>
        {/* </Link> */}
      </Container>
      {/* {showModal ? (
        <ProductQuickView setShowModal={setShowModal} product={item} />
      ) : (
        <></>
      )} */}
    </>
  );
};

export default Product;

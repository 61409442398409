import { Facebook, Instagram, WhatsApp } from "@material-ui/icons";
import React, { useState } from "react";
import "./SocialLink.css";
import styled from "styled-components";

const SocialIcon = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background-color: #${(props) => props.color}; */
  background-color: transparent;
  /* color: white; */
`;

const SocialLinks = () => {
  const [showicon, setShowIcon] = useState(false);
  return (
    <div className="social-links-container">
      <div className="cont-icons">
        {showicon ? (
          <div>
            <div className="icon-main">
              <a
                // href="https://api.whatsapp.com/send?phone=905537845741&text=Hello%2C%20I'm%20here%20about..."
                href="https://wa.me/message/4757DW7H47AUK1"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon color="E8C287">
                  <WhatsApp style={{ fontSize: "30px" }} htmlColor="#E8C287" />
                </SocialIcon>
              </a>
            </div>
            <br />
            <div className="icon-main">
              <a
                href="https://www.instagram.com/fabric.designer.outlet1/"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon color="E8C287">
                  <Instagram style={{ fontSize: "30px" }} htmlColor="#E8C287" />
                </SocialIcon>
              </a>
            </div>
            <br />
            <div className="icon-main">
              <a
                href="https://m.me/fabricdesigneroutlet1"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon color="E8C287">
                  <Facebook style={{ fontSize: "30px" }} htmlColor="#E8C287" />
                </SocialIcon>
              </a>
            </div>
            <br />
          </div>
        ) : (
          <></>
        )}
        <div className="icon-main">
          <SocialIcon color="E8C287">
            <div
              className="icon-text-container"
              onClick={() => setShowIcon(!showicon)}
            >
              <p className="icon-text-main">Help</p>
            </div>
            {/* <Add
              htmlColor="#E8C287"
              style={{ fontSize: "30px" }}
              onClick={() => setShowIcon(!showicon)}
            /> */}
          </SocialIcon>
        </div>
        <br />
      </div>
    </div>
  );
};

export default SocialLinks;

import React, { useEffect, useState } from "react";
import { IMG_URL, publicRequest } from "../../requestMethod";
import "./Blog.css";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import PreLoader from "../../components/PreLoader/PreLoader";

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const getAllBlogs = async () => {
      const res = await publicRequest.get("blog");
      setBlogs(res.data);
    };
    getAllBlogs();
  }, []);
  return (
    <>
      {blogs.length === 0 ? (
        <PreLoader />
      ) : (
        <div>
          <h1 className="blog-heading">Our Blogs</h1>
          <div className="blog-main-container">
            {blogs.map((blog, index) => (
              <>
                <Link
                  to={
                    blog.slug === "/"
                      ? `/blogs/${blog.slug.slice(1)}`
                      : `/blogs/${blog.slug}`
                  }
                  style={{ width: "90%" }}
                >
                  <div className="blog-container" key={index}>
                    {blog?.image[0]?.url !== undefined ? (
                      <img
                        src={`${IMG_URL}${blog?.image[0]?.url}`}
                        alt="blog"
                        style={{ height: "100px", width: "100px" }}
                        className="blog-image"
                      />
                    ) : (
                      <></>
                    )}
                    <div className="blog-description">
                      <h3>{blog.title}</h3>
                      <h6 className="blog-description-detail">
                        {ReactHtmlParser(blog.description)}
                      </h6>
                    </div>
                  </div>
                </Link>
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AllBlogs;

import React, { useState } from "react";
import people from "./data";
// import { FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import "./Feedback.css";

const Review = () => {
  const [index, setIndex] = useState(0);
  // const { name, image, text, location } = people[index];
  const { name, image } = people[index];

  const checkNumber = (number) => {
    if (number > people.length - 1) {
      return 0;
    } else if (number < 0) {
      return people.length - 1;
    }
    return number;
  };

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  const randomPerson = () => {
    let randomNumber = Math.floor(Math.random() * people.length);
    if (randomNumber === index) {
      randomNumber = index + 1;
    }
    setIndex(checkNumber(randomNumber));
  };

  return (
    <article className="review">
      <div className="img-container">
        <img src={image} alt={name} className="person-img" />
        {/* <span className="quote-icon">
          <FormatQuoteRounded />
        </span> */}
      </div>
      {/* <h4 className="author">{name}</h4> */}
      {/* <p className="jon">{job}</p> */}
      {/* <p className="info">{text}</p> */}
      {/* <p className="info">{location}</p> */}
      <div className="button-container">
        <button className="prev-btn" onClick={prevPerson}>
          {/* <FaChevronLeft /> */}
          <ArrowLeft />
        </button>
        <button className="next-btn" onClick={nextPerson}>
          {/* <FaChevronRight /> */}
          <ArrowRight />
        </button>
      </div>
      <button className="random-btn" onClick={randomPerson}>
        Suprise Me!
      </button>
    </article>
  );
};

export default Review;

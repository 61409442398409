import React from "react";
import styled from "styled-components";
// import Announcement from "../components/Announcement";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
// import Menu from "../components/Menu/Menu";
import { Add, Remove } from "@material-ui/icons";
// import CustomNavbar from "../components/CustomNavbar";
import MainHeader from "../components/MainHeader";
import { mobile } from "../responsive";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import PreLoader from "../components/PreLoader/PreLoader";
// import ReactImageZoom from "react-image-zoom";
import { publicRequest } from "../requestMethod";
// import { IMG_URL } from "../requestMethod";
import { addProducts } from "../redux/cartRedux";
import { useDispatch, useSelector } from "react-redux";
import ErrorPage from "../components/ErrorPage";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductImageSlider from "../components/ProductImageSlider";
import ReactHtmlParser from "react-html-parser";
import RelatedProducts from "../components/RelatedProducts/RelatedProducts";

const Container = styled.div`
  ${mobile({ marginTop: "-80px" })};
`;
const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  padding-top: 0px;
  ${mobile({ padding: "10px", flexDirection: "column" })};
`;
const ImgContainer = styled.div`
  /* border: 2px solid red; */
  min-width: 50vw;
  flex: 1;
`;

// const Image = styled.img`
//   width: 100%;
//   height: 60vh;
//   object-fit: contain;
//   ${mobile({ height: "40vh" })};
// `;
// const MulitImageSubContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin-top: 20px;
//   flex: 2;
//   padding-left: 40px;
//   z-index: 2;
//   cursor: pointer;
//   /* ${mobile({ flexWrap: "wrap" })}; */
// `;
// const MultipleImage = styled.img`
//   width: auto;
//   height: 20vh;
//   padding: 10px;
//   z-index: 2;
//   object-fit: contain;
//   ${mobile({ height: "20vh", width: "100px" })};
// `;
const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "10px" })};
`;
const Title = styled.h1`
  font-weight: 200;
`;
const Description = styled.p`
  margin: 20px 0px;
  font-size: 16px;
`;
const Price = styled.span`
  font-weight: 100;
  font-size: 40px;
`;

const FilterContainer = styled.div`
  width: 50%;
  margin: 30px 0px;
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  ${mobile({ width: "100%", marginLeft: "-10px" })};
`;
const Filter = styled.div`
  display: flex;
  align-items: center;
`;
const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
  margin-left: 10px;
`;
// const FilterColor = styled.div`
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   background-color: ${(props) => props.color};
//   margin: 0px 5px;
//   cursor: pointer;
// `;
const FilterSize = styled.select`
  margin-left: 20px;
  padding: 5px;
`;
const FilterSizeOption = styled.option``;
const AddContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ width: "100%" })};
`;
const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;
const Amount = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid teal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;
const Button = styled.button`
  padding: 15px;
  border: 2px solid teal;
  background-color: white;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #f8f4f4;
  }
`;

const Product = () => {
  const location = useLocation();
  const product_slug = location.pathname.split("/")[2];
  // const [mainImage, setMainImage] = useState();
  const [loader, setLoader] = useState(false);
  const [product, setProduct] = useState(null);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [servercolor, setServerColor] = useState([]);
  const [serversize, setServerSize] = useState("");
  // const [color, setColor] = useState("");
  // const [size, setSize] = useState("");
  const [selectedcolor, setSelectedColor] = useState();
  const [selectedsize, setSelectedSize] = useState();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  function extractVideoId(url) {
    const match = url.match(
      /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?feature=player_embedded&v=|\/watch\?feature=player_embedded&v=|\/watch\?v=|\/watch\?feature=player_embedded&v=))([\w\d_-]{11})/
    );
    return match ? match[1] : null;
  }

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await publicRequest.get(`products/slug/${product_slug}`);
        setProduct(res.data);
        // setMainImage(res.data?.images[0]?.guid);
        setLoader(true);
        const serverclr = res.data?.color[0]?.split(",");
        const servercolor = [];
        serverclr?.map((e) => {
          if (e !== "") {
            return servercolor.push({ color: e });
          }
          return true;
        });
        if (servercolor?.length !== 0) {
          servercolor.unshift({ color: "Select Color" });
          setSelectedColor(servercolor[0].color);
        }
        // setColor(servercolor[0].color);
        setServerColor(servercolor);
        const serversze = res.data?.size[0]?.split(",");
        const serversize = [];
        serversze?.map((e) => {
          if (e !== "") {
            return serversize.push({ size: e });
          }
          return true;
        });
        if (serversize?.length !== 0) {
          serversize.unshift({ size: "Select Size" });
          setSelectedSize(serversize[0].size);
        }
        setServerSize(serversize);
        const response = await publicRequest.get(
          `products/related-products/?category=${res.data.category}&sub_category=${res.data.sub_category}`
        );
        setRelatedProduct(response);
      } catch (err) {
        console.log(err);
        setLoader(true);
        setProduct(null);
      }
    };
    getProduct();
  }, [product_slug]);
  // const zoomin = {
  //   zoomPosition: "original",
  //   width: 370,
  //   // height: 400,
  //   zoomWidth: 500,
  //   img: `${IMG_URL}${mainImage}`,
  // };
  // const price = product?.max_price?.substr(0, product.max_price.length - 2);
  const price = Math.floor(product?.max_price);

  const handleQuantity = (type) => {
    if (type === "inc") {
      setQuantity(quantity + 1);
    } else {
      quantity > 1 && setQuantity(quantity - 1);
    }
  };
  const handleClick = () => {
    if (servercolor.length !== 0 || serversize.length !== 0) {
      if (
        (servercolor.length !== 0 && selectedcolor === "") ||
        (servercolor.length !== 0 && selectedcolor === "Select Color")
      ) {
        toast.error("Please Select Color");
      } else if (
        (serversize.length !== 0 && selectedsize === "") ||
        (serversize.length !== 0 && selectedsize === "Select Size")
      ) {
        toast.error("Please Select Size");
      } else {
        var product_exist = false;
        if (cart.products.length !== 0) {
          cart.products.map((prod) => {
            if (servercolor.length !== 0 && serversize.length !== 0) {
              if (
                prod._id === product._id &&
                prod.color === selectedcolor &&
                prod.size === selectedsize
              ) {
                product_exist = true;
              }
            } else if (servercolor.length !== 0) {
              if (prod._id === product._id && prod.color === selectedcolor) {
                product_exist = true;
              }
            } else if (serversize.length !== 0) {
              if (prod._id === product._id && prod.size === selectedsize) {
                product_exist = true;
              }
            }
            return true;
          });
          if (product_exist) {
            toast.error("Product Already In Cart With Same Color & Size");
          } else {
            const color = selectedcolor;
            const size = selectedsize;
            dispatch(addProducts({ ...product, quantity, color, size }));
          }
        } else {
          const color = selectedcolor;
          const size = selectedsize;
          dispatch(addProducts({ ...product, quantity, color, size }));
        }
        // const color = selectedcolor;
        // const size = selectedsize;
        // dispatch(addProducts({ ...product, quantity, color, size }));
      }
    } else {
      dispatch(addProducts({ ...product, quantity }));
    }
  };
  return (
    <>
      {loader ? (
        <>
          {product === null ? (
            <>
              <Container>
                <MainHeader />
                <ErrorPage />
                <Newsletter />
                <Footer />
              </Container>
            </>
          ) : (
            <Container>
              <meta name="Product" content={product?.post_title} />
              <MainHeader />
              <Wrapper>
                <ImgContainer>
                  <ProductImageSlider images={product?.images} />
                  {/* <ReactImageZoom {...zoomin} /> */}
                </ImgContainer>

                <InfoContainer>
                  <Title>{product.post_title}</Title>
                  <Description>{product.post_name}</Description>
                  {product?.new_description !== "undefined" && (
                    <div style={{ fontSize: "16px" }}>
                      {ReactHtmlParser(product.new_description)}
                    </div>
                  )}
                  <Price>£ {price}</Price>
                  <FilterContainer>
                    {servercolor.length !== 0 ? (
                      <Filter>
                        <FilterTitle>Color</FilterTitle>
                        <FilterSize
                          value={selectedcolor}
                          onChange={(e) => {
                            setSelectedColor(e.target.value);
                          }}
                        >
                          {/* <FilterSizeOption>Select Color</FilterSizeOption> */}
                          {servercolor.map((value) => (
                            <FilterSizeOption
                              value={value.color}
                              key={value.color}
                            >
                              {value.color}
                            </FilterSizeOption>
                          ))}
                        </FilterSize>
                      </Filter>
                    ) : (
                      <></>
                    )}
                    {serversize.length !== 0 ? (
                      <Filter>
                        <FilterTitle>Size</FilterTitle>
                        <FilterSize
                          value={selectedsize}
                          onChange={(e) => {
                            setSelectedSize(e.target.value);
                          }}
                        >
                          {/* <FilterSizeOption value="">
                            Select Size
                          </FilterSizeOption> */}
                          {serversize.map((value) => (
                            <FilterSizeOption
                              value={value.size}
                              key={value.size}
                            >
                              {value.size}
                            </FilterSizeOption>
                          ))}
                        </FilterSize>
                      </Filter>
                    ) : (
                      <></>
                    )}
                  </FilterContainer>
                  <AddContainer>
                    <AmountContainer>
                      <Remove onClick={() => handleQuantity("dec")} />
                      <Amount>{quantity}</Amount>
                      <Add onClick={() => handleQuantity("inc")} />
                    </AmountContainer>
                    <Button onClick={handleClick}>ADD TO CART</Button>
                    {/* <Link to="/cart/" style={{ textDecoration: "none" }}>
                </Link> */}
                  </AddContainer>
                </InfoContainer>
              </Wrapper>
              {product?.youtube_url && (
                <div className="container mt-5">
                  {/* YouTube Video */}
                  <div className="embed-responsive embed-responsive-16by9 d-flex align-items-center justify-content-center">
                    <iframe
                      title="YouTube Video"
                      className="embed-responsive-item"
                      style={{
                        width: "450px",
                        height: "350px",
                        margin: "0 auto",
                      }}
                      src={`https://www.youtube.com/embed/${extractVideoId(
                        product?.youtube_url
                      )}`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              )}
              {/* <MulitImageSubContainer>
                {product.images.map((item) => (
                  <MultipleImage
                    src={`${IMG_URL}${item.guid}`}
                    key={item.guid}
                    onClick={() => {
                      setMainImage(item.guid);
                    }}
                  />
                ))}
              </MulitImageSubContainer> */}
              {/* <ProductImageSlider images={product?.images} /> */}
              <RelatedProducts relatedProduct={relatedProduct} />
              <Footer />
            </Container>
          )}
        </>
      ) : (
        <PreLoader hidden={loader} />
      )}
      {/* <ToastContainer /> */}
      <SocialLinks />
      <ToastContainer />
    </>
  );
};

export default Product;

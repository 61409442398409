import React, { useEffect } from "react";
import MainHeader from "../components/MainHeader";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { address } from "../redux/AddressRedux";
import { address } from "../redux/AddressRedux";
import { price } from "../redux/FinalCartPriceRedux";
import { useNavigate } from "react-router-dom";
import { IMG_URL } from "../requestMethod";
// import { logout } from "../redux/userRedux";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import axios from "axios";
import { logout } from "../redux/userRedux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Order = () => {
  const [f_name, setFname] = useState("");
  const [l_name, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, selectCountry] = useState("");
  const [region, selectRegion] = useState("");
  const [zip, setZip] = useState("");
  const [cupon, setCupon] = useState("");
  const [supercupon, setSuperCupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [priceshow, setPriceShow] = useState(null);
  const [setordercoupon, setOrderCoupon] = useState(null);
  const [shipping, setShipping] = useState(0);
  const [phone, setPhone] = useState();
  // const [cupon_btn, setcupon_btn] = useState(false);
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const [freeitemship, setFreeItemShip] = useState(null);
  const [specialCouponApplied, setspecialCouponApplied] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (cart.total <= 100) {
      setShipping(6);
      setPriceShow(parseInt(cart.total) + 6);
      const pric = parseInt(cart.total) + 6;
      dispatch(price({ final_price: pric }));
    }
    if (cart.products.length === 0) {
      navigate("/");
    }

    /* Removed User Authentication on the page */

    // if (user.currentUser === null) {
    //   navigate("/login");
    // }

    const url = `${IMG_URL}api/globalcupon/productno?product_no=${cart.quantity}`;
    // const head = {
    //   headers: { token: `Bearer ${user?.currentUser?.accessToken}` },
    // };
    axios
      .get(url)
      .then((res) => {
        if (res.data === null) {
          try {
            const url = `${IMG_URL}api/freeprodcoupon/productno?product_no=${cart.quantity}`;
            const head = {
              headers: { token: `Bearer ${user?.currentUser?.accessToken}` },
            };
            axios
              .get(url, head)
              .then((response) => {
                const d1 = new Date();
                const d2 = new Date(response.data.to_date);
                const checkdate = d2.toLocaleDateString();
                const currentDate = d1.toLocaleDateString();
                const minimumProduct =
                  parseInt(response.data.buy_no_of_item) + 1;
                if (response.data !== null) {
                  if (minimumProduct > cart.quantity) {
                  } else {
                    if (response.data.is_active === false) {
                      toast.error("Cupon Is Not Active");
                    } else if (checkdate < currentDate) {
                      toast.error("Cupon Is Expired");
                    } else if (parseInt(cart.quantity) < minimumProduct) {
                      toast.error(
                        `Please Buy More Than ${minimumProduct} Products`
                      );
                    } else if (cart.quantity >= minimumProduct) {
                      let pricearr = [];
                      cart?.products?.map((price) => {
                        let quant = parseInt(price.quantity);
                        for (let i = 0; i < quant; i++) {
                          pricearr.push(Math.floor(price.max_price));
                        }
                        return true;
                      });
                      let min = Math.min(...pricearr);
                      let index = pricearr.indexOf(min);
                      pricearr.splice(index, 1);
                      let allprod_price = pricearr.reduce(
                        (total, number) => total + number,
                        0
                      );
                      let ship;
                      if (min > 0 && min <= 45) {
                        ship = 5;
                      } else if (min > 45 && min <= 100) {
                        ship = 10;
                      } else if (min > 100 && min <= 150) {
                        ship = 15;
                      } else if (min > 150) {
                        ship = 25;
                      }
                      if (
                        (cart.quantity === minimumProduct * 2 ||
                          cart.quantity > minimumProduct * 2) &&
                        cart.quantity < minimumProduct * 3
                      ) {
                        let min1 = Math.min(...pricearr);
                        let index = pricearr.indexOf(min1);
                        pricearr.splice(index, 1);
                        let allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let ship2;
                        if (min1 > 0 && min1 <= 45) {
                          ship2 = 5;
                        } else if (min1 > 45 && min1 <= 100) {
                          ship2 = 10;
                        } else if (min1 > 100 && min1 <= 150) {
                          ship2 = 15;
                        } else if (min1 > 150) {
                          ship2 = 25;
                        }
                        setCupon(response.data.cupon_code);
                        setFreeItemShip(ship + ship2);
                        setPriceShow(
                          parseInt(allprod_price) + shipping + ship + ship2
                        );
                        dispatch(
                          price({
                            final_price:
                              parseInt(allprod_price) + shipping + ship + ship2,
                          })
                        );
                        setDiscount(min + min1);
                      } else if (
                        (cart.quantity === minimumProduct * 3 ||
                          cart.quantity > minimumProduct * 3) &&
                        cart.quantity < minimumProduct * 4
                      ) {
                        let min1 = Math.min(...pricearr);
                        let index = pricearr.indexOf(min1);
                        pricearr.splice(index, 1);
                        let allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let min2 = Math.min(...pricearr);
                        let index1 = pricearr.indexOf(min2);
                        pricearr.splice(index1, 1);
                        allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let ship2;
                        if (min1 > 0 && min1 <= 45) {
                          ship2 = 5;
                        } else if (min1 > 45 && min1 <= 100) {
                          ship2 = 10;
                        } else if (min1 > 100 && min1 <= 150) {
                          ship2 = 15;
                        } else if (min1 > 150) {
                          ship2 = 25;
                        }
                        let ship3;
                        if (min2 > 0 && min2 <= 45) {
                          ship3 = 5;
                        } else if (min2 > 45 && min2 <= 100) {
                          ship3 = 10;
                        } else if (min2 > 100 && min2 <= 150) {
                          ship3 = 15;
                        } else if (min2 > 150) {
                          ship3 = 25;
                        }
                        setCupon(response.data.cupon_code);
                        setFreeItemShip(ship + ship2 + ship3);
                        setPriceShow(
                          parseInt(allprod_price) +
                            shipping +
                            ship +
                            ship2 +
                            ship3
                        );
                        dispatch(
                          price({
                            final_price:
                              parseInt(allprod_price) +
                              shipping +
                              ship +
                              ship2 +
                              ship3,
                          })
                        );
                        setDiscount(min + min1 + min2);
                      } else if (
                        (cart.quantity === minimumProduct * 4 ||
                          cart.quantity > minimumProduct * 4) &&
                        cart.quantity < minimumProduct * 5
                      ) {
                        let min1 = Math.min(...pricearr);
                        let index = pricearr.indexOf(min1);
                        pricearr.splice(index, 1);
                        let allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let min2 = Math.min(...pricearr);
                        let index1 = pricearr.indexOf(min2);
                        pricearr.splice(index1, 1);
                        allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let min3 = Math.min(...pricearr);
                        let index2 = pricearr.indexOf(min3);
                        pricearr.splice(index2, 1);
                        allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let ship2;
                        if (min1 > 0 && min1 <= 45) {
                          ship2 = 5;
                        } else if (min1 > 45 && min1 <= 100) {
                          ship2 = 10;
                        } else if (min1 > 100 && min1 <= 150) {
                          ship2 = 15;
                        } else if (min1 > 150) {
                          ship2 = 25;
                        }
                        let ship3;
                        if (min2 > 0 && min2 <= 45) {
                          ship3 = 5;
                        } else if (min2 > 45 && min2 <= 100) {
                          ship3 = 10;
                        } else if (min2 > 100 && min2 <= 150) {
                          ship3 = 15;
                        } else if (min2 > 150) {
                          ship3 = 25;
                        }
                        let ship4;
                        if (min3 > 0 && min3 <= 45) {
                          ship4 = 5;
                        } else if (min3 > 45 && min3 <= 100) {
                          ship4 = 10;
                        } else if (min3 > 100 && min3 <= 150) {
                          ship4 = 15;
                        } else if (min3 > 150) {
                          ship4 = 25;
                        }
                        setCupon(response.data.cupon_code);
                        setFreeItemShip(ship + ship2 + ship3 + ship4);
                        setPriceShow(
                          parseInt(allprod_price) +
                            shipping +
                            ship +
                            ship2 +
                            ship3 +
                            ship4
                        );
                        dispatch(
                          price({
                            final_price:
                              parseInt(allprod_price) +
                              shipping +
                              ship +
                              ship2 +
                              ship3 +
                              ship4,
                          })
                        );
                        setDiscount(min + min1 + min2 + min3);
                      } else if (
                        (cart.quantity === minimumProduct * 5 ||
                          cart.quantity > minimumProduct * 5) &&
                        cart.quantity < minimumProduct * 6
                      ) {
                        let min1 = Math.min(...pricearr);
                        let index = pricearr.indexOf(min1);
                        pricearr.splice(index, 1);
                        let allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let min2 = Math.min(...pricearr);
                        let index1 = pricearr.indexOf(min2);
                        pricearr.splice(index1, 1);
                        allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let min3 = Math.min(...pricearr);
                        let index2 = pricearr.indexOf(min3);
                        pricearr.splice(index2, 1);
                        allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let min4 = Math.min(...pricearr);
                        let index3 = pricearr.indexOf(min4);
                        pricearr.splice(index3, 1);
                        allprod_price = pricearr.reduce(
                          (total, number) => total + number,
                          0
                        );
                        let ship2;
                        if (min1 > 0 && min1 <= 45) {
                          ship2 = 5;
                        } else if (min1 > 45 && min1 <= 100) {
                          ship2 = 10;
                        } else if (min1 > 100 && min1 <= 150) {
                          ship2 = 15;
                        } else if (min1 > 150) {
                          ship2 = 25;
                        }
                        let ship3;
                        if (min2 > 0 && min2 <= 45) {
                          ship3 = 5;
                        } else if (min2 > 45 && min2 <= 100) {
                          ship3 = 10;
                        } else if (min2 > 100 && min2 <= 150) {
                          ship3 = 15;
                        } else if (min2 > 150) {
                          ship3 = 25;
                        }
                        let ship4;
                        if (min3 > 0 && min3 <= 45) {
                          ship4 = 5;
                        } else if (min3 > 45 && min3 <= 100) {
                          ship4 = 10;
                        } else if (min3 > 100 && min3 <= 150) {
                          ship4 = 15;
                        } else if (min3 > 150) {
                          ship4 = 25;
                        }
                        let ship5;
                        if (min4 > 0 && min4 <= 45) {
                          ship5 = 5;
                        } else if (min4 > 45 && min4 <= 100) {
                          ship5 = 10;
                        } else if (min4 > 100 && min4 <= 150) {
                          ship5 = 15;
                        } else if (min4 > 150) {
                          ship5 = 25;
                        }
                        setCupon(response.data.cupon_code);
                        setFreeItemShip(ship + ship2 + ship3 + ship4 + ship5);
                        setPriceShow(
                          parseInt(allprod_price) +
                            shipping +
                            ship +
                            ship2 +
                            ship3 +
                            ship4 +
                            ship5
                        );
                        dispatch(
                          price({
                            final_price:
                              parseInt(allprod_price) +
                              shipping +
                              ship +
                              ship2 +
                              ship3 +
                              ship4 +
                              ship5,
                          })
                        );
                        setDiscount(min + min1 + min2 + min3 + min4);
                      } else {
                        setCupon(response.data.cupon_code);
                        setFreeItemShip(ship);
                        setPriceShow(parseInt(allprod_price) + shipping + ship);
                        dispatch(
                          price({
                            final_price:
                              parseInt(allprod_price) + shipping + ship,
                          })
                        );
                        setDiscount(min);
                      }
                    } else {
                      toast.error(
                        `Please Add Atleast ${minimumProduct} Products To Apply This Cupon`
                      );
                    }
                  }
                }
              })
              .catch((err) => {
                toast.error("Invalid Coupon");
                if (err.response.status === 401) {
                  dispatch(logout());
                }
              });
          } catch (err) {
            // dispatch(logout());
            toast.error("Cupon Does Not Exist");
            setCupon("");
          }
        }
        if (res.data !== null) {
          if (res.data.is_active === true) {
            setCupon(res.data.cupon_code);
            // setcupon_btn(true);
            const cart_total = parseInt(cart.total);
            let final_price =
              cart_total - (cart_total * parseInt(res.data.cupon_amount)) / 100;
            const discount =
              (cart_total * parseInt(res.data.cupon_amount)) / 100;
            if (parseInt(cart.total) <= 100) {
              final_price = parseInt(final_price) + 6;
              setPriceShow(final_price);
              dispatch(price({ final_price: final_price }));
            } else {
              final_price = parseInt(final_price);
              setPriceShow(final_price);
              dispatch(price({ final_price: final_price }));
            }
            setDiscount(discount);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(logout());
        }
      });
  }, [
    cart.products.length,
    user.currentUser,
    navigate,
    cart.quantity,
    cart.total,
    dispatch,
    cart.products,
    shipping,
  ]);

  const handleCupon = async (event) => {
    event.preventDefault();
    if (cupon === "") {
      toast.error("Please Enter Coupon");
    } else {
      try {
        const url = `${IMG_URL}api/freeprodcoupon/cuponname?cupon=${cupon}&username=${user.currentUser.username}`;
        const head = {
          headers: { token: `Bearer ${user?.currentUser?.accessToken}` },
        };
        axios
          .get(url, head)
          .then((response) => {
            const d1 = new Date();
            const d2 = new Date(response.data.to_date);
            const checkdate = d2.toLocaleDateString();
            const currentDate = d1.toLocaleDateString();
            const minimumProduct = parseInt(response.data.buy_no_of_item) + 1;
            if (response.data.is_active === false) {
              toast.error("Cupon Is Not Active");
            } else if (checkdate < currentDate) {
              toast.error("Cupon Is Expired");
            } else if (parseInt(cart.total) < minimumProduct) {
              toast.error(`Please Buy More Than ${minimumProduct} Products`);
            } else if (cart.quantity >= minimumProduct) {
              let pricearr = [];
              cart?.products?.map((price) => {
                let quant = parseInt(price.quantity);
                for (let i = 0; i < quant; i++) {
                  pricearr.push(Math.floor(price.max_price));
                }
                return true;
              });
              let min = Math.min(...pricearr);
              let index = pricearr.indexOf(min);
              pricearr.splice(index, 1);
              let allprod_price = pricearr.reduce(
                (total, number) => total + number,
                0
              );
              let ship;
              if (min > 0 && min <= 40) {
                ship = 5;
              } else if (min > 40 && min <= 100) {
                ship = 10;
              } else if (min > 100 && min <= 150) {
                ship = 15;
              } else if (min > 150) {
                ship = 25;
              }
              setPriceShow(parseInt(allprod_price) + 6 + ship);
              dispatch(
                price({ final_price: parseInt(allprod_price) + 6 + ship })
              );
              setDiscount(min);
            } else {
              toast.error(
                `Please Add Atleast ${minimumProduct} Products To Apply This Cupon`
              );
            }
          })
          .catch((err) => {
            toast.error("Invalid Coupon");
            if (err.response.status === 401) {
              dispatch(logout());
            }
          });
      } catch (err) {
        // dispatch(logout());
        toast.error("Cupon Does Not Exist");
        setCupon("");
      }
    }
  };
  const handleSpecialCupon = async (event) => {
    event.preventDefault();
    if (cupon === "") {
      toast.error("Please Enter Coupon");
    } else {
      try {
        const url = `${IMG_URL}api/supercupon/cuponname?cupon=${supercupon}&username=${user.currentUser.username}`;
        const head = {
          headers: { token: `Bearer ${user.currentUser.accessToken}` },
        };
        axios
          .get(url, head)
          .then((response) => {
            const d1 = new Date();
            const d2 = new Date(response.data[0].to_date);
            const checkdate = d2.toLocaleDateString();
            const currentDate = d1.toLocaleDateString();
            if (response.data[0].is_active === false) {
              toast.error("Cupon Is Not Active");
            } else if (checkdate < currentDate) {
              toast.error("Cupon Is Expired");
            } else if (
              parseInt(cart.total) <= parseInt(response.data[0].cupon_amount)
            ) {
              toast.error(
                `Please Buy More Than £ ${response.data[0].cupon_amount}`
              );
            } else if (cart.quantity >= response.data[0].product_no) {
              const cart_total = parseInt(cart.total);
              let final_price =
                cart_total - parseInt(response.data[0].cupon_amount);
              const discount_price = parseInt(response.data[0].cupon_amount);

              // const final_price =
              //   cart_total -
              //   (cart_total * parseInt(response.data[0].cupon_amount)) / 100;
              // const discount =
              //   (cart_total * parseInt(response.data[0].cupon_amount)) / 100;
              if (parseInt(cart.total) <= 100) {
                if (specialCouponApplied) {
                  toast.error("Coupon Already Applied");
                } else {
                  final_price = parseInt(final_price) + 6;
                  if (discount !== 0) {
                    setPriceShow(final_price - discount + freeitemship);
                    setCupon(supercupon);
                  } else {
                    setPriceShow(final_price);
                  }
                  dispatch(
                    price({
                      final_price: final_price - discount + freeitemship,
                    })
                  );
                  setspecialCouponApplied(true);
                  setDiscount(discount + discount_price);
                }
              } else {
                if (specialCouponApplied) {
                  toast.error("Coupon Already Applied");
                } else {
                  final_price = parseInt(final_price);
                  if (discount !== 0) {
                    setPriceShow(final_price - discount + freeitemship);
                    setCupon(supercupon);
                  } else {
                    setPriceShow(final_price);
                  }
                  dispatch(
                    price({
                      final_price: final_price - discount + freeitemship,
                    })
                  );
                  setspecialCouponApplied(true);
                  setDiscount(discount + discount_price);
                }
              }
              setOrderCoupon(response.data[0]);
            } else {
              toast.error(
                `Please Add ${response.data[0].product_no} Products To Apply This Cupon`
              );
            }
          })
          .catch((err) => {
            toast.error("Invalid Coupon");
            if (err.response.status === 401) {
              dispatch(logout());
            }
          });
      } catch (err) {
        // dispatch(logout());
        toast.error("Cupon Does Not Exist");
        setCupon("");
      }
    }
  };
  const handleForm = (event) => {
    event.preventDefault();
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (f_name.length < 3 || parseInt(f_name)) {
      toast.error("Please Enter A Valid First Name");
    } else if (l_name.length < 3 || parseInt(l_name)) {
      toast.error("Please Enter A Valid Last Name");
    } else if (!regEx.test(email)) {
      toast.error("Please Enter A Valid Email");
    } else if (address1.length < 5) {
      toast.error("Please Enter A Valid Address");
    } else if (city.length < 3 || parseInt(city)) {
      toast.error("Please Enter A Valid City Name");
    } else if (!phone) {
      toast.error("Please Enter Your Phone Number");
    }
    // else if (zip.length !== 5 || !parseInt(zip)) {
    //   toast.error("Please Enter A Valid Zip Code");
    // }
    else {
      dispatch(
        address({
          first_name: f_name,
          last_name: l_name,
          email: email,
          address1: address1,
          address2: address2,
          city: city,
          country: country,
          region: region,
          zip: zip,
          phone: phone,
          cupon: cupon,
        })
      );
      const cart_total = parseInt(cart.total);
      if (cupon === "") {
        if (parseInt(cart.total) <= 100) {
          let final_price = parseInt(cart_total) + 6;
          dispatch(price({ final_price: final_price }));
        } else {
          let final_price = parseInt(cart_total);
          dispatch(price({ final_price: final_price }));
        }
      }
      if (setordercoupon !== null) {
        navigate("/payment", { state: setordercoupon });
      } else {
        navigate("/payment");
      }
    }
  };
  return (
    <>
      <MainHeader />
      <SocialLinks />
      <div className="container">
        <div className="row">
          <div className="col-md-4 order-md-2 mb-4">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-muted">Apply Cupon</span>
              <span className="badge badge-secondary badge-pill">3</span>
            </h4>
            <form className="card p-2" onSubmit={handleCupon}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cupon Code"
                  onChange={(e) => setCupon(e.target.value)}
                  value={cupon}
                />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-secondary">
                    Redeem
                  </button>
                </div>
              </div>
            </form>
            <form className="card p-2" onSubmit={handleSpecialCupon}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Special Coupon"
                  onChange={(e) => setSuperCupon(e.target.value)}
                  value={supercupon}
                />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-secondary">
                    Redeem
                  </button>
                </div>
              </div>
            </form>
            <ul className="list-group mb-3">
              <li className="list-group-item d-flex justify-content-between bg-light">
                <div className="text-success">
                  <h6 className="my-0">Promo code</h6>
                  <small>{cupon}</small>
                </div>
                <span className="text-success">-£{Math.floor(discount)}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between bg-light">
                <div className="text-success">
                  <h6 className="my-0">Shipping</h6>
                  {/* <small>{cupon}</small> */}
                </div>
                <span className="text-success">£{Math.floor(shipping)}</span>
              </li>
              {freeitemship !== null ? (
                <li className="list-group-item d-flex justify-content-between bg-light">
                  <div className="text-success">
                    <h6 className="my-0">Free Item Shipping</h6>
                    {/* <small>{freeitemship}</small> */}
                  </div>
                  <span className="text-success">
                    £{Math.floor(freeitemship)}
                  </span>
                </li>
              ) : (
                <></>
              )}
              <li className="list-group-item d-flex justify-content-between">
                <span>Total</span>
                <strong>
                  £{Math.floor(priceshow !== null ? priceshow : cart.total)}
                </strong>
              </li>
            </ul>
          </div>
          <div className="col-md-8 order-md-1">
            <h2 className="mb-3">Billing address</h2>
            <form className="needs-validation" onSubmit={handleForm}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="firstName">First name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder=""
                    required
                    onChange={(e) => setFname(e.target.value)}
                    value={f_name}
                  />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder=""
                    required
                    onChange={(e) => setLname(e.target.value)}
                    value={l_name}
                  />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="you@example.com"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <div className="invalid-feedback">
                  Please enter a valid email address for shipping updates.
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="address">Billing Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Billing Addess"
                  required
                  onChange={(e) => setAddress1(e.target.value)}
                  value={address1}
                />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="address2">
                  Shipping Address{" "}
                  <span className="text-muted">(Optional)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address2"
                  placeholder="Shipping Address"
                  onChange={(e) => setAddress2(e.target.value)}
                  value={address2}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="Chicago"
                  required
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 mb-3">
                  <label htmlFor="country">Country</label>
                  <CountryDropdown
                    value={country}
                    onChange={(val) => selectCountry(val)}
                    className="custom-select d-block w-100"
                    required
                  />
                  <div className="invalid-feedback">
                    Please select a valid country.
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="state">State</label>
                  <RegionDropdown
                    country={country}
                    value={region}
                    onChange={(val) => selectRegion(val)}
                    className="custom-select d-block w-100"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide a valid state.
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <label>Phone Number</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="zip">Zip</label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    placeholder=""
                    // required
                    onChange={(e) => setZip(e.target.value)}
                    value={zip}
                  />
                  <div className="invalid-feedback">Zip code required.</div>
                </div>
              </div>
              <button
                className="btn btn-dark btn-lg btn-block mb-5"
                type="submit"
              >
                Proceed To Pay £
                {Math.floor(priceshow !== null ? priceshow : cart.total)}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Order;

import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { login } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import PreLoader from "../components/PreLoader/PreLoader";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "../components/MainHeader";
import { ToastContainer, toast } from "react-toastify";
// import FacebookLogin from "react-facebook-login";
import "./Page.css";
// import { GoogleLogin } from "@react-oauth/google";
// import jwt_decode from "jwt-decode";
import { publicRequest } from "../requestMethod";
// import { loginSuccess } from "../redux/userRedux";
import { useEffect } from "react";

const Container = styled.div`
  margin-top: -175px;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://images.pexels.com/photos/6984661/pexels-photo-6984661.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  padding: 20px;
  width: 25%;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10%;
  ${mobile({ width: "75%" })};
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0px;
  padding: 10px;
`;
const Button = styled.button`
  width: 60%;
  border: none;
  padding: 15px 20px;
  background-color: #171717;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
`;
const LinkCu = styled.span`
  margin: 5px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: black;
`;
const Error = styled.span`
  color: red;
`;
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showComponent, setShowComponent] = useState("Login");
  // const [forgetPassword, setForgetPassword] = useState(null);
  const [otp, setOtp] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [NewPasswordConfirm, setNewPasswordConfirm] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const { isFetching, error } = useSelector((state) => state.user);
  const handleLogin = (e) => {
    e.preventDefault();
    if (username === "") {
      toast.error("Please Enter Username");
    } else if (password === "") {
      toast.error("Please Enter Password");
    } else {
      login(dispatch, { username, password });
    }
  };
  // const handleFacebookError = () => {
  //   return toast.error("Facebook Login Failed");
  // };
  const handleForget = async () => {
    if (username === "") {
      toast.error("Please Enter Username Or Email");
    } else {
      const data = {
        username: username,
      };
      publicRequest
        .post("users/changePassword", data)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Otp is sent on your email address");
            setShowComponent("Otp");
          }
        })
        .catch((err) => {
          toast.error("Invalid Email Address");
        });
    }
  };
  const handleOtp = async (e) => {
    e.preventDefault();
    const data = {
      username: username,
      otp: otp,
    };
    const response = await publicRequest.post("users/otp", data);
    if (response.status === 200) {
      setShowComponent("NewPassword");
    } else {
      toast.error("Invalid Otp");
    }
  };
  const handleNewPassword = async (e) => {
    e.preventDefault();
    if (NewPassword === "") {
      toast.error("Please Enter Password");
    } else if (NewPassword.length < 8) {
      toast.error("Please Enter Password Of Length 8 Characters");
    } else if (NewPasswordConfirm === "") {
      toast.error("Please Enter Confirm Password");
    } else if (NewPasswordConfirm !== NewPassword) {
      toast.error("Password & Confirm Password Doesn't Match");
    } else {
      const data = {
        username: username,
        otp: otp,
        password: NewPassword,
      };
      const response = await publicRequest.post("users/updatepassword", data);
      if (response.status === 200) {
        toast.success("Password Updated Successfully");
        const password = NewPassword;
        login(dispatch, { username, password });
      } else {
        toast.error("Invalid Email Address");
      }
    }
  };
  useEffect(() => {
    if (user?.currentUser !== null) {
      navigate("/");
    }
  }, [user?.currentUser, navigate]);
  return (
    <>
      <MainHeader />
      {isFetching ? (
        <PreLoader />
      ) : (
        <Container>
          <Wrapper>
            {showComponent === "Login" ? (
              <>
                <Title>SIGN IN</Title>
                <Form>
                  <Input
                    placeholder="Username Or Email"
                    value={username}
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <Input
                    placeholder="Password"
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button onClick={handleLogin}>LOGIN</Button>
                  {/* <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    cssClass="facebook-btn"
                    icon="fa fa-facebook"
                    textButton="Login"
                    callback={async (res) => {
                      const data = {
                        first_name: res.name?.split(" ")[0],
                        last_name: res.name?.split(" ")[1],
                        name: res.name,
                        email: res.email,
                        picture: res.picture.data.url,
                        login_type: "Facebook",
                      };
                      const response = await publicRequest.post(
                        "auth/sociallogin",
                        data
                      );
                      dispatch(loginSuccess(response.data));
                    }}
                    onFailure={handleFacebookError}
                  /> */}
                  {/* <GoogleLogin
                    onSuccess={async (credentialResponse) => {
                      const res = jwt_decode(credentialResponse.credential);
                      const data = {
                        first_name: res.given_name,
                        last_name: res.family_name,
                        name: res.name,
                        email: res.email,
                        picture: res.picture,
                        login_type: "Google",
                      };
                      const response = await publicRequest.post(
                        "auth/sociallogin",
                        data
                      );
                      dispatch(loginSuccess(response.data));
                    }}
                    onError={() => {
                      toast.error("Login With Google Failed");
                    }}
                  /> */}
                  {error && <Error>Invalid Credentials</Error>}
                  <LinkCu onClick={handleForget}>FORGET PASSWORD?</LinkCu>
                  <Link to="/register">
                    <LinkCu>CREATE A NEW ACCOUNT</LinkCu>
                  </Link>
                </Form>
              </>
            ) : showComponent === "Otp" ? (
              <>
                <Title>Enter Otp</Title>
                <Form>
                  <Input
                    placeholder="Enter Otp"
                    value={otp}
                    type="number"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <Button onClick={handleOtp}>Continue</Button>
                </Form>
              </>
            ) : showComponent === "NewPassword" ? (
              <>
                <Title>Enter New Password</Title>
                <Form>
                  <Input
                    placeholder="New Password"
                    value={NewPassword}
                    type="password"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <Input
                    placeholder="Confirm Password"
                    value={NewPasswordConfirm}
                    type="password"
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                  />
                  <Button onClick={handleNewPassword}>Update Password</Button>
                </Form>
              </>
            ) : (
              <></>
            )}
          </Wrapper>
        </Container>
      )}
      <ToastContainer />
    </>
  );
};
export default Login;

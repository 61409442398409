import React, { useEffect } from "react";
import "./UserInfo.css";
import { MailOutline, PermIdentity, PhoneAndroid } from "@material-ui/icons";
import { IMG_URL } from "../../requestMethod";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import axios from "axios";

const UserInfo = () => {
  const userinfo = useSelector((state) => state.user.currentUser);
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const TOKEN = userinfo.accessToken;
  useEffect(() => {
    setUser(userinfo);
    setUsername(userinfo.username);
    setFirstName(userinfo.first_name);
    setLastName(userinfo.last_name);
    setPhone(userinfo.phone);
    setEmail(userinfo.email);
  }, [userinfo]);
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (password !== "") {
      const data = {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        password: password,
      };
      const config = {
        headers: {
          token: `Bearer ${TOKEN}`,
        },
      };
      const url = `${IMG_URL}api/users/${userinfo._id}`;

      axios
        .put(url, data, config)
        .then((res) => {
          setUser(res.data);
          setUsername(res.data.username);
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          setPhone(res.data.phone);
          setEmail(res.data.email);
          toast.success("User Updated Successfully");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
    if (password === "") {
      const data = {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
      };
      const config = {
        headers: {
          token: `Bearer ${TOKEN}`,
        },
      };
      const url = `${IMG_URL}api/users/${userinfo._id}`;

      axios
        .put(url, data, config)
        .then((res) => {
          setUser(res.data);
          setUsername(res.data.username);
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          setPhone(res.data.phone);
          setEmail(res.data.email);
          toast.success("User Updated Successfully");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };  
  return (
    <div className="user">
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <img
              src="https://www.focusedu.org/wp-content/uploads/2018/12/circled-user-male-skin-type-1-2.png"
              alt=""
              className="userShowImg"
            />
            <div className="userShowTopTitle">
              <span className="userShowUsername">
                {user?.first_name} {user?.last_name}
              </span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowUserTitle">Account Details</span>
            <div className="userShowInfo">
              ID:
              <b>
                <div className="userShowInfoTitle">{user?._id}</div>
              </b>
            </div>
            <div className="userShowInfo">
              Username:
              <PermIdentity className="userShowIcon" />
              <b>
                <div className="userShowInfoTitle">{user?.username}</div>
              </b>
            </div>
            <div className="userShowInfo">
              First Name:
              <b>
                <div className="userShowInfoTitle">{user?.first_name}</div>
              </b>
            </div>
            <div className="userShowInfo">
              Last Name:
              <b>
                <div className="userShowInfoTitle">{user?.last_name}</div>
              </b>
            </div>
            <span className="userShowUserTitle">Contact Details</span>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <div className="userShowInfoTitle">{user?.phone}</div>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <div className="userShowInfoTitle">{user?.email}</div>
            </div>
          </div>
        </div>
        <div className="userUpdate">
          {user?.login_type === undefined || user?.login_type === null ? (
            <>
              <span className="userUpdateTitle">Edit</span>
              <form className="userUpdateForm">
                <div className="userUpdateLeft">
                  <div className="userUpdateItem">
                    <label>Username</label>
                    <input
                      type="text"
                      placeholder="Username"
                      className="userUpdateInput"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="userUpdateItem">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="userUpdateInput"
                      value={first_name}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="userUpdateItem">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="userUpdateInput"
                      value={last_name}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="userUpdateItem">
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      className="userUpdateInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="userUpdateItem">
                    <label>Phone</label>
                    <input
                      type="number"
                      placeholder="Phone Number"
                      className="userUpdateInput"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="userUpdateItem">
                    <label>Password</label>
                    <input
                      type="password"
                      placeholder="Update Password"
                      className="userUpdateInput"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button className="userUpdateButton" onClick={handleUpdate}>
                    Update
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <span className="userUpdateTitle">
                Can't edit your profile as you are logged in using social login
              </span>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserInfo;

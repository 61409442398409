import React from "react";
import ReactCardCarousel from "react-card-carousel";
import "./NewCat.css";
import { categ } from "./data";
import { Link } from "react-router-dom";

const NewCatgcard = () => {
  return (
    <>
      <div className="container-style-cour">
        <ReactCardCarousel autoplay={true} autoplay_speed={2000}>
          {categ.map((data, index) => (
            <div className="card-style-cour" key={index}>
              <img className="card-image" src={data.img} alt="" />
              <Link to={`${data.url}`} style={{ textDecoration: "none" }}>
                <h4 className="card-head">{data.text}</h4>
              </Link>
            </div>
          ))}
          {/* <div className="card-style-cour">Second Card</div>
          <div className="card-style-cour">Third Card</div>
          <div className="card-style-cour">Fourth Card</div>
          <div className="card-style-cour">Fifth Card</div> */}
        </ReactCardCarousel>
      </div>
    </>
  );
};

export default NewCatgcard;

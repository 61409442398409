import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    quantity: 0,
    total: 0,
    final_price: 0,
  },
  reducers: {
    addProducts: (state, action) => {
      state.quantity += action.payload.quantity;
      state.products.push(action.payload);
      state.total += action.payload.max_price * action.payload.quantity;
      state.final_price = state.total;
    },
    emptyCart: (state) => {
      state.products = [];
      state.quantity = 0;
      state.total = 0;
      state.final_price = 0;
    },
    productIncQuantity: (state, action) => {
      state.products.map((prod) => {
        if (
          prod._id === action.payload.product_id &&
          prod.color === action.payload.product_color &&
          prod.size === action.payload.product_size
        ) {
          prod.quantity = action.payload.quantity_update;
        }
        return true;
      });
      state.quantity += 1;
      state.total += parseInt(action.payload.product_price);
      state.final_price = state.total;
    },
    productDecQuantity: (state, action) => {
      if (
        action.payload.quantity_update === 0 ||
        action.payload.quantity_update < 0
      ) {
        const objWithIdIndex = state.products.findIndex(
          (obj) =>
            obj._id === action.payload.product_id &&
            obj.color === action.payload.product_color &&
            obj.size === action.payload.product_size
        );
        if (objWithIdIndex > -1) {
          state.products.splice(objWithIdIndex, 1);
        }
      }
      state.products.map((prod) => {
        if (
          prod._id === action.payload.product_id &&
          prod.color === action.payload.product_color &&
          prod.size === action.payload.product_size
        ) {
          prod.quantity = action.payload.quantity_update;
        }
        return true;
      });
      state.quantity -= 1;
      state.total -= parseInt(action.payload.product_price);
      state.final_price = state.total;
    },
  },
});

export const {
  addProducts,
  emptyCart,
  productIncQuantity,
  productDecQuantity,
} = cartSlice.actions;
export default cartSlice.reducer;

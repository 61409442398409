import "./App.css";
import Cart from "./pages/Cart";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProductList from "./pages/ProductList";
import CustomHome from "./pages/CustomHome";
import Login from "./pages/Login";
import Register from "./pages/Register";
// import Home from "./pages/Home";
import Product from "./pages/Product";
import ProductListCategory from "./pages/ProductListCategory";
import Payment from "./pages/Payment/Payment";
import Success from "./pages/Success";
import Order from "./pages/Order";
import { useSelector } from "react-redux";
import CompleteLogin from "./pages/CompleteLogin";
import NotFound from "./pages/NotFound";
import ProductSearchList from "./pages/ProductSearch";
import Dashboard from "./pages/dashboard/Dashboard";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Blogs from "./pages/blog/Blogs";
import SingleBlog from "./pages/blog/SingleBlog";
import GuestSuccess from "./pages/GuestSuccess";

function App() {
  const user = useSelector((state) => state.user.currentUser);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CustomHome />} />
        <Route path="/terms-condition" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
        <Route
          path="/register"
          element={user ? <Navigate to="/" /> : <Register />}
        />
        <Route
          path="/dashboard"
          element={!user ? <Navigate to="/" /> : <Dashboard />}
        />
        <Route
          path="/completelogin"
          element={user ? <Navigate to="/" /> : <CompleteLogin />}
        />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/products/:category" element={<ProductList />} />
        <Route
          path="/products/category/:category"
          element={<ProductListCategory />}
        />
        <Route
          path="/products/subcategory/:subcategory"
          element={<ProductListCategory />}
        />
        <Route
          path="/products/search/:search"
          element={<ProductSearchList />}
        />
        <Route path="/cart/" element={<Cart />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/order" element={<Order />} />
        <Route path="/success" element={<Success />} />
        <Route path="/order/success" element={<GuestSuccess />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<SingleBlog />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import styled from "styled-components";
const Container = styled.div`
  /* text-align: center; */
  margin-top: -50px;
  margin-left: 10px;
  margin-right: 10px;
  /* justify-content: center;
  align-items: center; */
`;
const PrivacyPolicy = () => {
  return (
    <>
      <MainHeader />
      <Container>
        <h1>PrivacyPolicy</h1>
        <br />
        <br />
        <h4>1. Introduction</h4>
        <p>Greetings from FabricDesignerOutlet.</p>
        <p>
          Operating fabricdesigneroutlet.com (hereafter referred to as
          "Service") is FabricDesignerOutlet ("us", "we," or "our").
        </p>
        <p>
          Our Privacy Policy regulates your visit to fabricdesigneroutlet.com
          and outlines how we gather, protect, and share information that comes
          from your use of our Service.
        </p>
        <p>
          Your data is used by us to deliver and enhance the Service. You
          consent to collecting and using the information in line with this
          policy by using the Service. Unless otherwise specified, the elements
          used in this privacy policy have the same meanings as those found in
          our terms and conditions. Your agreement with us (the "Agreement") is
          governed by our Terms and Conditions ("Terms"), which also govern any
          uses of our Service.
        </p>
        <h4>2. Definitions</h4>
        <h6>SERVICE </h6>
        <p>
          refers to the FabricdesignerOutlet website at
          fabricdesigneroutlet.com.
        </p>
        <h6>PERSONAL DATA </h6>
        <p>
          is information that can be used to identify a living person (or from
          those and other information either in our possession or likely to come
          into our possession).
        </p>
        <h6>USAGE DATA </h6>
        <p>
          is information gathered automatically from the infrastructure of the
          service or from data produced by the use of the service? (for example,
          the duration of a page visit).
        </p>
        <h6>COOKIES </h6>
        <p>
          are there any little files on your device? (computer or mobile
          device).
        </p>
        <h6>DATA CONTROLLER </h6>
        <p>
          implies a natural or legal person who decides how personal data will
          be processed, including the reasons why and how they will be processed
          (either alone, jointly, or in common with other people). We are a Data
          Controller for the purposes of this Privacy Policy with regard to your
          data.
        </p>
        <h6>DATA PROCESSORS (OR SERVICE PROVIDERS) </h6>
        <p>
          indicates any individual—natural or legal—who handles data processing
          for the Data Controller. In order to process your data more
          efficiently, we may employ the services of different Service
          Providers.
        </p>
        <h6>DATA SUBJECT </h6>
        <p>is any living person whose personal data is in question.</p>
        <h6>THE USER </h6>
        <p>
          is the person making use of our Service. The Data Subject, who is the
          object of Personal Data, is the User.
        </p>
        <h4>3. Information Collection and Use</h4>
        <p>
          In order to deliver and enhance our Service to you, we gather a
          variety of information from you.
        </p>
        <h4>4. Types of Data Collected</h4>
        <h6>Personal Data</h6>
        <p>
          When using our service, we may require you to give us certain
          personally identifying information (also known as "Personal Data")
          that can be used to get in touch with or identify you. Among other
          things, personally identifiable information could be:
        </p>
        <ol start="1">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, Country, State, Province, ZIP/Postal code, City</li>
          <li>Cookies and Usage Data</li>
        </ol>
        <p>
          To contact you with newsletters, marketing or promotional materials,
          and other information that may be of interest to you, we may utilize
          your Personal Data. By clicking the unsubscribe link in any or all of
          these emails from us, you can choose not to receive them.
        </p>
        <h6>Usage Data</h6>
        <p>
          We may also gather data from your browser whenever you use any device
          to use our service or when you visit our site ("Usage Data").
        </p>
        <p>
          This Usage Data may contain particulars like the Internet Protocol
          address (or IP address) of your computer, the kind and version of your
          browser, the pages of our Service that you visit, when and how long
          you spend on those pages, specific device identifiers, and other
          diagnostic data.
        </p>
        <p>
          When you use a device to access the Service, this Usage Data may
          contain details like the kind of device you use, its unique ID, its IP
          address, its operating system, the kind of web browser you use, as
          well as other diagnostic data.
        </p>
        <h6>Tracking Cookies Data</h6>
        <p>
          We track user activity on our Service using cookies and other tracking
          technologies, and we save some data.
        </p>
        <p>
          Cookies are little data files that may contain an anonymous unique
          identification. Cookies are little text files that a website sends to
          your browser and stores there. Beacons, tags, and scripts are just a
          few of the tracking technologies used to gather data, track users, and
          analyze and enhance our service.
        </p>
        <p>
          You can tell your browser to always reject cookies or to let you know
          when one is being sent. But, you might not be able to utilize all of
          our Service if you refuse to accept cookies.
        </p>
        <p>Examples of Cookies we use:</p>
        <ol start="1">
          <li>
            Session Cookies: We use Session Cookies to operate our Service.
          </li>
          <li>
            Preference Cookies: We use Preference Cookies to remember your
            preferences and various settings.
          </li>
          <li>
            Security Cookies: We use Security Cookies for security purposes.
          </li>
          <li>
            Advertising Cookies: Advertising Cookies are used to serve you with
            advertisements that may be relevant to you and your interests.
          </li>
        </ol>
        <h6>Other Data</h6>
        <p>
          While utilizing our Service, we may also gather the following data:
          sex, age, date of birth, place of birth, passport information,
          citizenship, registration at place of residence and actual address,
          telephone number (work, mobile), specifics of documents pertaining to
          education, qualification, professional training, employment
          agreements, NDA agreements, information on bonuses and compensation,
          information on marital status, family members, social security (or
          other taxpayer identification number), and any other information that
          may be relevant to our understanding of you.
        </p>
        <h4>5. Use of Data</h4>
        <p>
          Fabricdesigneroutlet uses the collected data for various purposes:
        </p>
        <ol start="1">
          <li>to offer and keep up our Service;</li>
          <li>to let you know when we make modifications to our service;</li>
          <li>
            allowing you to engage with our Service's interactive features when
            you wish to;
          </li>
          <li>to offer client service;</li>
          <li>
            to compile research or useful data in order to enhance our service;
          </li>
          <li>to keep tabs on how our Service is being used;</li>
          <li>to identify, stop, and fix technical problems;</li>
          <li>to accomplish any other goal for which you supply it;</li>
          <li>
            to fulfill our commitments and uphold our rights under any
            agreements we may have made with you, including those pertaining to
            billing and collection;
          </li>
          <li>
            to send you notifications regarding your account and/or
            subscription, such as email instructions, expiration and renewal
            notices, unless you have chosen not to receive this information.
          </li>
          <li>
            to inform you of news, special offers, and general information about
            other products, services, and events we offer that are comparable to
            those you have already purchased or inquired about;{" "}
          </li>
          <li>
            in any other manner we may specify when you provide the information;
          </li>
        </ol>
        <h4>6. Retention of Data</h4>
        <p>
          Your Personal Data will only be kept by us for as long as it is
          required to fulfill the objectives outlined in this privacy statement.
          To the extent necessary to meet our legal duties (for instance, if
          relevant laws compel us to keep your data on file), resolve disputes,
          and uphold our agreements and policies, we will keep and use your
          personal data.
        </p>
        <p>
          Usage Data will also be kept by us for internal analysis needs. Usage
          Data is typically held for less time. Nevertheless, it may be kept
          longer if necessary to comply with legal obligations, resolve
          disputes, or enhance the performance of our Service.
        </p>
        <h4>7. Transfer of Data</h4>
        <p>
          Your data, including Personal Data, might be moved to and kept on
          computers situated outside of your state, province, nation, or another
          governmental jurisdiction where the data protection rules might be
          different from those of your jurisdiction.
        </p>
        <p>
          Please be aware that if you choose to give us information while
          residing outside of the world, we will transmit and process your
          personal data there.
        </p>
        <p>
          Your permission to that transfer is shown by your acceptance of this
          Privacy Policy and your submission of the requested information.
        </p>
        <p>
          No transfer of your personal data to a company or country will happen
          unless there are adequate controls in place, including the security of
          your data and other personal information. Fabricdesigneroutlet will
          take all the steps that are reasonably necessary to ensure that your
          data is treated securely and in accordance with this Privacy Policy.
        </p>
        <h4>8. Disclosure of Data</h4>
        <p>
          We may share the following types of personal data that you give us or
          that we collect:
        </p>
        <h6>0.1. Disclosure for Law Enforcement.</h6>
        <p>
          In some cases, we may have to divulge your personal information in
          order to comply with legal obligations or in order to meet legitimate
          government requests.
        </p>
        <h6>0.2. Business Transaction.</h6>
        <p>
          Your Personal Data might be transferred if we or one of our
          subsidiaries is involved in a merger, acquisition, or asset sale.
        </p>
        <p>
          Other situations, To fulfill the purpose for which you provide it,
        </p>
        <p>
          to contractors, service providers, and other third parties we use to
          support our business;
        </p>
        <p>in order to display your company's logo on our website;</p>
        <p>
          for any other purpose disclosed by us when you provide the
          information;
        </p>
        <p>with your consent in any other situations; and</p>
        <p>if we deem disclosure necessary.</p>
        <h4>9. Security of Data</h4>
        <p>
          We care about the security of your data, but keep in mind that no form
          of electronic data storage or transfer over the Internet is 100% safe.
          While we make an effort to protect your Personal Data with
          commercially reasonable measures, we cannot provide perfect assurance
          of security.
        </p>
        <h4>
          10. Your Data Protection Rights Under General Data Protection
          Regulation (GDPR)
        </h4>
        <p>
          You have specific data protection rights under GDPR if you live in the
          European Union (EU) or the European Economic Area (EEA).
        </p>
        <p>
          We try to make it as easy as possible for you to change, remove, or
          restrict how your personal data is used.
        </p>
        <p>
          Email us at fabric.designer.outlet@gmail.com if you want to know what
          Personal Data we have about you and if you want it to be deleted from
          our systems.
        </p>
        <p>
          You may exercise the following data protection rights under specific
          conditions:
        </p>
        <p>
          0.1. the right to see, modify, or remove the data we have on you;0.2
        </p>
        <p>
          0.2. If the information is incorrect or incomplete, you have the right
          to have it corrected;
        </p>
        <p>
          0.3. the freedom to disagree. You are allowed to object to how we are
          using your personal information;
        </p>
        <p>
          0.4. You have the right to ask us to limit how we use your personal
          information;
        </p>
        <p>
          0.5. the ability to transfer info. You have the right to receive a
          copy of your personal data in a format that is organized,
          machine-readable, and widely accepted;
        </p>
        <p>
          Please be aware that before responding to such inquiries, we can ask
          you to prove your identity. Please be aware that without some data, we
          may not be able to deliver the Service.
        </p>
        <p>
          Regarding the way we collect and use your personal data, you have the
          right to file a complaint with a data protection authority. Please get
          in touch with your local data protection authority in the European
          Economic Area for additional details (EEA).
        </p>
        <h4>
          11. Your Data Protection Rights under the California Privacy
          Protection Act (CalOPPA)
        </h4>
        <p>
          CalOPPA is the first state law in the country to mandate the posting
          of a privacy statement on commercial websites and online services. The
          law's reach extends well beyond California, requiring anyone or any
          organization operating websites in the United States (or perhaps the
          entire world) that collect personally identifiable information from
          California consumers to post a clear privacy statement on their
          website, comply with this statement, and disclose exactly what
          information is being collected and with whom it will be shared.
        </p>
        <p>We concur with the following, via CalOPPA:</p>
        <p>0.1. Users can visit our website anonymously.</p>
        <p>
          0.2. Our Privacy Policy link, which includes the word "Privacy," is
          prominently displayed on our home page. Users will be informed of any
          privacy policy changes on our Privacy Policy Page.Users can update
          their personal information by emailing us at
          fabric.designer.outlet@gmail.com.
        </p>
        <p>Our "Do Not Track" Signals Policy:</p>
        <p>
          We respect Do Not Track browser mechanisms and do not monitor, put
          cookies on, or use advertising when one is present.
        </p>
        <p>
          You can tell websites that you do not want to be tracked by using the
          Do Not Track feature in your web browser.
        </p>
        <p>
          By going to your web browser's Preferences or Settings page, you can
          set or disable Do Not Track.
        </p>
        <h4>
          12. Your Data Protection Rights under the California Consumer Privacy
          Act (CCPA)
        </h4>
        <p>
          If you live in California, you have the right to know what information
          we have about you, request its deletion, and request that it not be
          sold (shared). To exercise your rights under data protection, you can
          contact us and make the following requests:
        </p>
        <h6>
          {" "}
          0.1. What personal information we have about you. If you make this
          request, we will return to you:
        </h6>
        <p>0.0.1. The kind of personal data we have amassed about you.</p>
        <p>
          The types of sources from which we gather your personal information
          are listed in paragraph
        </p>
        <p>
          The categories of sources from which we obtain your personal data are
          listed in paragraph
        </p>
        <p>
          0.0.2. The business or commercial reason for obtaining or
          disseminating your personal information,
        </p>
        <p>
          0.0.3 The kinds of third parties with whom we disclose personal
          information at 0.0.4
        </p>
        <p>
          0.0.5. The particular pieces of personal data we have gathered about
          you.
        </p>
        <p>
          0.0.6. A list of the kinds of personal data we have sold, together
          with the classification of any other businesses we may have done so.
          We will let you know if your personal information hasn't been sold.
        </p>
        <p>
          0.0.7. A list of the types of personal data that we have shared with
          other companies for business purposes, along with the category of
          those companies.
        </p>
        <p>
          0.2. To remove your private data. The personal data we have on you as
          of the date of your request will be removed from our records if you
          make this request, and we will instruct any service providers to do
          the same. De-identification of the data can sometimes be used to
          achieve deletion. You might not be able to access some functions if
          you decide to erase your personal information because they depend on
          it to work.
        </p>
        <p>
          To cease selling your private information. Your personal information
          is never rented or sold to outside parties. Your personal information
          is never sold to other parties for profit. But, in some instances, a
          non-cash transfer of personal information to a third party or another
          member of our family of businesses may be regarded as a "sale" under
          California law. Your Personal Data belongs to you alone, and you have
          the right to seek its disclosure or deletion at any time.
        </p>
        <p>
          We shall stop making such transfers if you ask us not to sell your
          personal information.
        </p>
        <p>
          Please be aware that if you ask us to delete or cease selling your
          data, it might affect how you interact with us and you might not be
          able to take advantage of certain membership services or programs that
          depend on the use of personal information to operate. Nonetheless, we
          would never treat you differently simply because you exercised your
          rights.
        </p>
        <p>
          Send your request(s) to fabric.designer.outlet@gmail.com to exercise
          your above-described California data protection rights.
        </p>
        <p>
          The CCPA, often known as the California Consumer Privacy Act, provides
          protection for the rights to privacy that were previously mentioned.
          Visit the official California Legislative Information page to learn
          more. The CCPA became active on January 1, 2020.
        </p>
        <h4>13. Service Providers</h4>
        <p>
          We might work with outside organizations and people (referred to as
          "Service Providers") to make our service available, offer services on
          our behalf, carry out service-related tasks, or help us analyze how
          our service is utilized.
        </p>
        <p>
          These third parties are only permitted to use your Personal Data to
          carry out these duties for us and are forbidden from using it or
          disclosing it for any other reason.
        </p>
        <h4>14. Analytics</h4>
        <p>
          To track and examine how our Service is being used, we could work with
          outside Service Providers.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

// import { Add, Remove } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import { mobile } from "../responsive";
import { useSelector } from "react-redux";
import { IMG_URL } from "../requestMethod";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  emptyCart,
  productIncQuantity,
  productDecQuantity,
} from "../redux/cartRedux";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import { Add, Remove } from "@material-ui/icons";

const Container = styled.div``;
const Wrapper = styled.div`
  margin-top: -80px;
  padding: 20px;
  ${mobile({ padding: "10px" })};
`;
const Title = styled.h1`
  font-family: "Urbanist", sans-serif;
  font-weight: 300;
  text-align: center;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
const TopButton = styled.button`
  font-family: "Urbanist", sans-serif;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => (props.type === "filled" ? "none" : "1px solid black")};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
  border-radius: 5px;
`;
const TopTexts = styled.div`
  ${mobile({ display: "none" })};
`;
const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })};
`;
const Info = styled.div`
  flex: 3;
`;

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })};
`;
const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;
const Image = styled.img`
  width: 200px;
  /* height: 200px; */
`;
const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const ProductName = styled.span``;

const ProductId = styled.span``;

// const ProductColor = styled.div`
//   height: 20px;
//   width: 20px;
//   border-radius: 50%;
//   background-color: ${(props) => props.color};
// `;

const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const ProductAmount = styled.div`
  font-size: 24px;
  margin: 5px;
  ${mobile({ margin: "5px 15px" })};
`;
const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${mobile({ marginBottom: "20px" })};
`;

const Hr = styled.hr`
  background-color: #a2a1a1;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 75vh;
`;

const SummaryTitle = styled.h1`
  font-family: "Urbanist", sans-serif;
  font-weight: 200;
  font-size: 30px;
`;
const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;
const SummaryItemText = styled.span``;
const SummaryItemPrice = styled.span``;
const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
`;

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleProductLink = (id) => {
    // navigate(`/product/${id}`);
  };
  return (
    <Container>
      <SocialLinks />
      <MainHeader />
      <Wrapper>
        <Title>YOUR BAG</Title>
        <Top>
          <Link to="/">
            <TopButton>CONTINUE SHOPPING</TopButton>
          </Link>
          <TopTexts>
            <TopText>Shopping Bag({cart.products.length})</TopText>
            <TopText>Your Wishlist(0)</TopText>
          </TopTexts>
          {cart.products.length !== 0 ? (
            <TopButton type="filled" onClick={() => navigate("/order")}>
              CHECKOUT NOW
            </TopButton>
          ) : (
            <></>
          )}
        </Top>
        {cart.products.length !== 0 ? (
          <Bottom>
            <Info>
              {cart?.products?.map((product) => (
                <div
                  key={product._id}
                  onClick={() => handleProductLink(product._id)}
                  style={{ zIndex: 2 }}
                >
                  <Product>
                    <ProductDetail>
                      <Image src={`${IMG_URL}${product?.images[0]?.guid}`} />
                      <Details>
                        <ProductName>
                          <b>Product:</b> {product.post_title}
                        </ProductName>
                        <ProductId>
                          <b>ID:</b> {product._id}
                        </ProductId>
                        <ProductSize>
                          <b>Color:</b>
                          {product.color}
                          {/* <ProductColor color={product.color} /> */}
                        </ProductSize>
                        <ProductSize>
                          <b>Size:</b> {product.size}
                        </ProductSize>
                      </Details>
                    </ProductDetail>
                    <PriceDetail>
                      <ProductAmountContainer>
                        Quantity:
                        <Remove
                          onClick={() => {
                            let quantity_update =
                              parseInt(product.quantity) - 1;
                            let product_id = product._id;
                            let product_price = product.max_price;
                            let product_color = product?.color;
                            let product_size = product?.size;
                            dispatch(
                              productDecQuantity({
                                quantity_update,
                                product_id,
                                product_price,
                                product_color,
                                product_size,
                              })
                            );
                          }}
                        />
                        <ProductAmount>{product.quantity}</ProductAmount>
                        <Add
                          onClick={() => {
                            let quantity_update =
                              parseInt(product.quantity) + 1;
                            let product_id = product._id;
                            let product_price = product.max_price;
                            let product_color = product?.color;
                            let product_size = product?.size;
                            dispatch(
                              productIncQuantity({
                                quantity_update,
                                product_id,
                                product_price,
                                product_color,
                                product_size,
                              })
                            );
                          }}
                        />
                      </ProductAmountContainer>
                      <ProductPrice>
                        £ {Math.floor(product.max_price)}
                        {/* {product.max_price.substr(
                          0,
                          product.max_price.length - 2
                        ) * product.quantity} */}
                      </ProductPrice>
                    </PriceDetail>
                  </Product>
                  <Hr />
                </div>
              ))}
            </Info>
            <Summary>
              <SummaryTitle>ORDER SUMMARY</SummaryTitle>
              <SummaryItem>
                <SummaryItemText>Subtotal</SummaryItemText>
                <SummaryItemPrice>£ {cart.total}</SummaryItemPrice>
              </SummaryItem>
              {/* <SummaryItem>
                <SummaryItemText>Estimated Shipping</SummaryItemText>
                <SummaryItemPrice>£ 0</SummaryItemPrice>
              </SummaryItem>
              <SummaryItem>
                <SummaryItemText>Shipping Discount</SummaryItemText>
                <SummaryItemPrice>£ 0</SummaryItemPrice>
              </SummaryItem> */}
              <SummaryItem type="total">
                <SummaryItemText>Total</SummaryItemText>
                <SummaryItemPrice>£ {cart.total}</SummaryItemPrice>
              </SummaryItem>
              <Button onClick={() => navigate("/order")}>CHECKOUT NOW</Button>
              <Button
                onClick={() => dispatch(emptyCart())}
                style={{ marginTop: "20px" }}
              >
                Empty Cart
              </Button>
            </Summary>
          </Bottom>
        ) : (
          <h1 style={{ textAlign: "center" }}>Your Cart Is Empty</h1>
        )}
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default Cart;

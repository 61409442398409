import React from "react";
import styled from "styled-components";
import "react-multi-carousel/lib/styles.css";
import "./FavouriteStyle.css";
import { Facebook, Instagram } from "@material-ui/icons";
import { Badge } from "@material-ui/core";
import { mobile } from "../../responsive";

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  /* background-color: #e8c287; */
  ${mobile({ backgroundColor: "#e8c287" })};
  /* display: flex; */
`;
const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;
const SocialIcon = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #${(props) => props.color};
  color: white;
`;
const HeadingText = styled.h1`
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
  font-family: "Playfair Display", serif;
`;
const InfoText = styled.h4`
  color: black;
  text-align: center;
  font-weight: bold;
`;
const FavouriteStyle = () => {
  return (
    <Container>
      <HeadingText>OUR SOCIAL ACCOUNT</HeadingText>
      <SocialContainer>
        <div className="card card-social" style={{ margin: "5px" }}>
          <a
            href="https://www.facebook.com/groups/327637241244423/?ref=share"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon color="">
              <Facebook style={{ fontSize: "120px" }} htmlColor="#3B5999" />
              <Badge
                badgeContent={`16k+`}
                color="primary"
                overlap="rectangular"
                className="facebookbadge"
              />
            </SocialIcon>
            <InfoText>16k+ Followers</InfoText>
          </a>
        </div>
        <div className="card card-social" style={{ margin: "5px" }}>
          <a
            href="https://www.instagram.com/fabric.designer.outlet1/"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon color="">
              <Badge
                badgeContent={`1.4k+`}
                color="secondary"
                overlap="rectangular"
                className="instabadge"
              />
              <Instagram style={{ fontSize: "120px" }} htmlColor="#E4405F" />
            </SocialIcon>
            <InfoText>1.4k+ Followers</InfoText>
          </a>
        </div>
      </SocialContainer>
    </Container>
  );
};

export default FavouriteStyle;

import React from "react";
import MainHeader from "../components/MainHeader";
import "./Page.css";
import logo from "../assets/logo.png";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import styled from "styled-components";
import { WhatsApp } from "@material-ui/icons";

const GuestSuccess = () => {
  const SocialContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
  `;
  const SocialIcon = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #${(props) => props.color};
    color: white;
  `;

  return (
    <>
      <MainHeader />
      <SocialLinks />
      <section className="login-main-wrapper">
        <div className="main-container">
          <div className="login-process">
            <div className="login-main-container">
              <div className="thankyou-wrapper">
                <h2>
                  <img
                    src={logo}
                    alt="thanks"
                    style={{ height: "200px", width: "200px" }}
                  />
                </h2>
                <h1>Thank You</h1>
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "50px",
                    marginTop: "-20px",
                  }}
                >
                  ❤️❤️❤️
                </h4>
                <p>
                  Your Order Has Been Received For Paypal & Bank Transfer Please
                  Contact Our Team :)
                </p>
                <h2>Our Contact</h2>
                <br />
                <SocialContainer>
                  <a
                    href="https://api.whatsapp.com/send?phone=905537845741&text=Hello%2C%20I'm%20here%20about..."
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialIcon color="128c7e">
                      <WhatsApp />
                    </SocialIcon>
                  </a>
                </SocialContainer>
                <br />
                <h5 style={{ textAlign: "center" }}>
                  <a href="/" className="website-link-b">
                    www.fabricdesigneroutlet.com
                  </a>
                </h5>
                <div className="clr"></div>
              </div>
              <div className="clr"></div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </section>
    </>
  );
};

export default GuestSuccess;

import img1 from "../../assets/f1.jpeg";
import img2 from "../../assets/f2.jpeg";
import img3 from "../../assets/f3.jpeg";
import img4 from "../../assets/f4.jpeg";
import img5 from "../../assets/f5.jpeg";
import img6 from "../../assets/f6.jpeg";
import img7 from "../../assets/f7.jpeg";
import img8 from "../../assets/f8.jpeg";
import img9 from "../../assets/f9.jpeg";
import img10 from "../../assets/f10.jpeg";
import img11 from "../../assets/f11.jpeg";
import img12 from "../../assets/f12.jpeg";
import img15 from "../../assets/f15.jpeg";
import img16 from "../../assets/f16.jpeg";
import img17 from "../../assets/f17.jpeg";
import img18 from "../../assets/f18.jpeg";
import img19 from "../../assets/f19.jpeg";
import img20 from "../../assets/f20.jpeg";

const reviews = [
  {
    id: 1,
    name: "Lorraine Sharp",
    image: img1,
    text: "Finally arrived due to postal strikes , which obviously couldn’t be helped Very happy with item Will defo use again 😊",
    location: "Russia",
  },
  {
    id: 2,
    name: "Tony Heslop",
    image: img2,
    text: "Quality from these guys at Fabric Online Shop is outstanding 👏🏼 Anything you need they are more than happy to help. More orders on the way 😁 Cheers lads 😉",
    location: "Canada",
  },
  {
    id: 3,
    name: "Greg Smith",
    image: img3,
    text: "Just got my delivery and this is absolutely stunning !   No different from the one in Harvey nicks retailing at £850 bargain for just £3O including postage ! Only took ten days too ! Thanks guys can’t wait to wear it and stand out from the crowd  🙏🥰",
    location: "United Kingdom",
  },
  {
    id: 4,
    name: "Jude Tuley",
    image: img4,
    text: "Really Happy with my purchases, fit perfectly. Thank You",
    location: "America",
  },
  {
    id: 5,
    name: "Lorraine Sharp",
    image: img5,
    text: "Finally arrived due to postal strikes , which obviously couldn’t be helped Very happy with item Will defo use again 😊",
    location: "Russia",
  },
  {
    id: 6,
    name: "Tony Heslop",
    image: img6,
    text: "Quality from these guys at Fabric Online Shop is outstanding 👏🏼 Anything you need they are more than happy to help. More orders on the way 😁 Cheers lads 😉",
    location: "Canada",
  },
  {
    id: 7,
    name: "Greg Smith",
    image: img7,
    text: "Just got my delivery and this is absolutely stunning !   No different from the one in Harvey nicks retailing at £850 bargain for just £3O including postage ! Only took ten days too ! Thanks guys can’t wait to wear it and stand out from the crowd  🙏🥰",
    location: "United Kingdom",
  },
  {
    id: 8,
    name: "Jude Tuley",
    image: img8,
    text: "Really Happy with my purchases, fit perfectly. Thank You",
    location: "America",
  },
  {
    id: 9,
    name: "Lorraine Sharp",
    image: img9,
    text: "Finally arrived due to postal strikes , which obviously couldn’t be helped Very happy with item Will defo use again 😊",
    location: "Russia",
  },
  {
    id: 10,
    name: "Tony Heslop",
    image: img10,
    text: "Quality from these guys at Fabric Online Shop is outstanding 👏🏼 Anything you need they are more than happy to help. More orders on the way 😁 Cheers lads 😉",
    location: "Canada",
  },
  {
    id: 11,
    name: "Greg Smith",
    image: img11,
    text: "Just got my delivery and this is absolutely stunning !   No different from the one in Harvey nicks retailing at £850 bargain for just £3O including postage ! Only took ten days too ! Thanks guys can’t wait to wear it and stand out from the crowd  🙏🥰",
    location: "United Kingdom",
  },
  {
    id: 12,
    name: "Jude Tuley",
    image: img12,
    text: "Really Happy with my purchases, fit perfectly. Thank You",
    location: "America",
  },
  {
    id: 15,
    name: "Lorraine Sharp",
    image: img15,
    text: "Finally arrived due to postal strikes , which obviously couldn’t be helped Very happy with item Will defo use again 😊",
    location: "Russia",
  },
  {
    id: 16,
    name: "Tony Heslop",
    image: img16,
    text: "Quality from these guys at Fabric Online Shop is outstanding 👏🏼 Anything you need they are more than happy to help. More orders on the way 😁 Cheers lads 😉",
    location: "Canada",
  },
  {
    id: 17,
    name: "Greg Smith",
    image: img17,
    text: "Just got my delivery and this is absolutely stunning !   No different from the one in Harvey nicks retailing at £850 bargain for just £3O including postage ! Only took ten days too ! Thanks guys can’t wait to wear it and stand out from the crowd  🙏🥰",
    location: "United Kingdom",
  },
  {
    id: 18,
    name: "Jude Tuley",
    image: img18,
    text: "Really Happy with my purchases, fit perfectly. Thank You",
    location: "America",
  },
  {
    id: 19,
    name: "Lorraine Sharp",
    image: img19,
    text: "Finally arrived due to postal strikes , which obviously couldn’t be helped Very happy with item Will defo use again 😊",
    location: "Russia",
  },
  {
    id: 20,
    name: "Tony Heslop",
    image: img20,
    text: "Quality from these guys at Fabric Online Shop is outstanding 👏🏼 Anything you need they are more than happy to help. More orders on the way 😁 Cheers lads 😉",
    location: "Canada",
  },
];

export default reviews;

import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IMG_URL } from "../../requestMethod";
import { useState } from "react";
import "./UserOrders.css";

const UserOrders = () => {
  const user = useSelector((state) => state.user.currentUser);
  const [orders, setOrders] = useState([]);
  const TOKEN = user.accessToken;
  useEffect(() => {
    const config = {
      headers: {
        token: `Bearer ${TOKEN}`,
      },
    };
    const url = `${IMG_URL}api/order/user/${user._id}`;
    axios
      .get(url, config)
      .then((res) => {
        setOrders(res.data.order);
      })
      .catch((err) => {});
  }, [TOKEN, user._id]);
  if (orders.length !== 0) {
    return (
      <>
        <div className="main-cont">
          {orders.map((order) => {
            var dat = new Date(order.createdAt);
            return (
                <div className="order-main-container" key={order._id}>
                  <div className="orderContainer">
                    <div className="info-content">
                      <span className="client-content">Order Id: </span>
                      <b className="server-content">{order._id}</b>
                    </div>
                    <br />
                    <div className="info-content">
                      <span className="client-content">Order Amount: </span>
                      <b className="server-content">
                        £{Math.floor(order.amount)}
                      </b>
                    </div>
                    <br />
                    <div className="info-content">
                      <span className="client-content">Order Status: </span>
                      <b className="server-content">{order.status}</b>
                    </div>
                    <br />
                    <div className="info-content">
                      <span className="client-content">
                        Tracking Number 1:{" "}
                      </span>
                      <b className="server-content">{order.tracking_number}</b>
                    </div>
                    {order.tracking_number2 ? (
                      <div className="info-content">
                        <span className="client-content">
                          Tracking Number 2:{" "}
                        </span>
                        <b className="server-content">
                          {order.tracking_number2}
                        </b>
                      </div>
                    ) : (
                      <></>
                    )}
                    <br />
                    {order.tracking_number3 ? (
                      <div className="info-content">
                        <span className="client-content">
                          Tracking Number 3:{" "}
                        </span>
                        <b className="server-content">
                          {order.tracking_number3}
                        </b>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="info-content">
                      {order.images.length > 0 ? (
                        <div>
                          <h3>Tracking Images</h3>
                          {order.images.map((img, i) => {
                            return (
                                <img
                                  src={`${IMG_URL}${img.url}`}
                                  alt=""
                                  key={i}
                                  className="prod-image"
                                  onClick={() => window.open(`${IMG_URL}${img.url}`)}
                                />
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <br />
                    <div className="info-content">
                      <span className="client-content">Order Date: </span>
                      <b className="server-content">{dat.toDateString()}</b>
                    </div>
                    <br />
                    {order?.products?.map((product) => {
                      return (
                        <>
                          <div className="info-content" key={product._id}>
                            <span className="client-content">
                              Product Name:{" "}
                            </span>
                            <b className="server-content">
                              {product?.post_title}
                            </b>
                          </div>
                          <br />
                          <div className="info-content">
                            <span className="client-content">
                              Product Price:{" "}
                            </span>
                            <b className="server-content">
                              £{Math.floor(product?.max_price)}
                            </b>
                          </div>
                          <br />
                          <img
                            src={`${IMG_URL}${product.images[0].guid}`}
                            alt=""
                            className="prod-image"
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <>
        <h3 style={{ textAlign: "center" }}>No Orders Found</h3>
      </>
    );
  }
};

export default UserOrders;

import React from "react";
import { IMG_URL } from "../requestMethod";
import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const ProductImageSlider = (props) => {
  const MulitImageSubContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    flex: 2;
    padding-left: 40px;
    z-index: 2;
    cursor: pointer;
  `;
  const images = [];
  props?.images?.map((img) => {
    images.push({
      original: `${IMG_URL}${img.guid}`,
      thumbnail: `${IMG_URL}${img.guid}`,
    });
    return true;
  });
  return (
    <div>
      {images.length !== 0 ? <ImageGallery items={images} /> : <></>}
      <MulitImageSubContainer></MulitImageSubContainer>
    </div>
  );
};

export default ProductImageSlider;

import React, { useEffect } from "react";
import "./Payment.css";
import Styles from "./Styles";
import { Form, Field } from "react-final-form";
import Card from "./Card";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./cardUtils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "../../components/MainHeader";
import { useDispatch } from "react-redux";
import { productPayment } from "../../redux/PaymentRedux";
import { emptyCart } from "../../redux/cartRedux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { mobile } from "../../responsive";
import styled from "styled-components";
// import { userRequest } from "../../requestMethod";
import { resetAddress } from "../../redux/AddressRedux";
import { logout } from "../../redux/userRedux";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import axios from "axios";
import { IMG_URL } from "../../requestMethod";
import { useState } from "react";
import { resetPrice } from "../../redux/FinalCartPriceRedux";

const Container = styled.div`
  margin-top: -80px;
  ${mobile({ marginTop: "-80px" })};
`;
const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState(null);
  const location = useLocation();
  const price = useSelector((state) => state.price);
  const address = useSelector((state) => state.address.address);
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user?.currentUser);
  // const token = useSelector((state) => state.user.currentUser.accessToken);
  useEffect(() => {
    if (location.state !== null) {
      setCoupon(location.state);
    }
    if (cart.products.length === 0) {
      navigate("/");
    }
    // if (user === null) {
    //   navigate("/login");
    // }
    if (Object.keys(address).length === 0 && address.constructor === Object) {
      navigate("/order");
    }
  }, [cart.products.length, navigate, address, location.state]);

  const validateCardNumber = (number) => {
    //Check if the number contains only numeric value
    //and is of between 13 to 19 digits
    const regex = new RegExp("^[0-9]{13,19}$");
    if (!regex.test(number)) {
      return false;
    }

    return luhnCheck(number);
  };

  const luhnCheck = (val) => {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }

      // Add the units element to the checksum total
      checksum = checksum + calc;

      // Switch the value of j
      if (j === 1) {
        j = 2;
      } else {
        j = 1;
      }
    }

    //Check if it is divisible by 10 or not.
    return checksum % 10 === 0;
  };

  // const handlePaypal = () => {
  //   dispatch(
  //     productPayment({
  //       holder_name: "Dummy",
  //       card_number: "Dummy",
  //       card_cvc: "Dummy",
  //       card_expiray: "Dummy",
  //     })
  //   );
  //   try {
  //     const data = {
  //       userId: "Guest",
  //       products: cart.products,
  //       total_cupon: 1,
  //       cupons: [
  //         {
  //           cupon_code: address.cupon,
  //         },
  //       ],
  //       payment: [
  //         {
  //           holder_name: "Dummy",
  //           card_number: "Dummy",
  //           card_cvc: "Dummy",
  //           card_expiray: "Dummy",
  //         },
  //       ],
  //       amount: price.price.final_price,
  //       address: address,
  //     };
  //     axios
  //       .post(`${IMG_URL}api/order/guest`, data, {
  //         headers: {
  //           "content-type": "application/json",
  //         },
  //       })
  //       .then((res) => {
  //         dispatch(emptyCart());
  //         dispatch(resetAddress());
  //         dispatch(resetPrice());
  //         navigate("/order/success");
  //       })
  //       .catch((err) => {
  //         toast.error("Please Login First To Proceed Payment By Card");
  //         if (err.response.status === 401) {
  //           dispatch(logout());
  //         }
  //       });
  //   } catch (error) {
  //     dispatch(logout());
  //   }
  // };

  const submitHandler = (e) => {
    // setPaymentMethod(e);
    let card_holder_name = e.name;
    let card_number = e.number;
    let card_expiry_date = e.expiry;
    let card_date_split = card_expiry_date.split("/");
    let card_date = card_date_split[0];
    let card_month = card_date_split[1];
    let card_cvc = e.cvc;
    card_number = card_number.replace(/\s/g, "");
    const res = validateCardNumber(card_number);
    if (card_holder_name === "") {
      toast("Invalid Name");
    } else if (card_holder_name.length < 3) {
      toast.error("Invalid Name");
    } else if (parseInt(card_holder_name) || parseFloat(card_holder_name)) {
      toast.error("Invalid Name");
    } else if (!res) {
      toast.error("Invalid Card Number");
    } else if (card_cvc.length < 2) {
      toast.error("Invalid CVC");
    } else if (parseInt(card_date) > 12) {
      toast.error("Invalid Card Expiry Date");
    } else if (parseInt(card_month) > 40 || parseInt(card_month) < 22) {
      toast.error("Invalid Card Expiry Date");
    } else {
      dispatch(
        productPayment({
          holder_name: card_holder_name,
          card_number: card_number,
          card_cvc: card_cvc,
          card_expiray: card_expiry_date,
        })
      );
      if ((card_holder_name, card_number, card_expiry_date, card_cvc)) {
        // toast.success("Payment Successful");

        try {
          if (coupon !== null) {
            const data = {
              userId: user?._id,
              products: cart.products,
              total_cupon: 1,
              user_cupon_id: coupon._id,
              current_usage_number: coupon.current_usage_number,
              cupons: [
                {
                  cupon_code: address.cupon,
                },
              ],
              payment: [
                {
                  holder_name: card_holder_name,
                  card_number: card_number,
                  card_cvc: card_cvc,
                  card_expiray: card_expiry_date,
                },
              ],
              amount: price.price.final_price,
              address: address,
            };
            axios
              .post(`${IMG_URL}api/order`, data, {
                headers: {
                  "content-type": "application/json",
                  // token: `Bearer ${token}`,
                },
              })
              .then((res) => {
                dispatch(emptyCart());
                dispatch(resetAddress());
                navigate("/success");
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  dispatch(logout());
                }
                toast.error("Please Login First To Proceed Payment By Card");
              });
          } else {
            const data = {
              userId: user?._id,
              products: cart.products,
              total_cupon: 1,
              cupons: [
                {
                  cupon_code: address.cupon,
                },
              ],
              payment: [
                {
                  holder_name: card_holder_name,
                  card_number: card_number,
                  card_cvc: card_cvc,
                  card_expiray: card_expiry_date,
                },
              ],
              amount: price.price.final_price,
              address: address,
            };
            axios
              .post(`${IMG_URL}api/order`, data, {
                headers: {
                  "content-type": "application/json",
                  // token: `Bearer ${token}`,
                },
              })
              .then((res) => {
                dispatch(emptyCart());
                dispatch(resetAddress());
                dispatch(resetPrice());
                navigate("/success", { user: "Guest" });
              })
              .catch((err) => {
                toast.error("Please Login First To Proceed Payment By Card");
                if (err.response.status === 401) {
                  dispatch(logout());
                }
              });
          }
        } catch (error) {
          dispatch(logout());
        }
      }
    }
  };

  return (
    <>
      <MainHeader />
      <SocialLinks />
      <Container>
        {!user && (
          <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
            Please Login To Pay By Card.
          </h1>
        )}
        <Styles>
          <Form
            onSubmit={submitHandler}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              active,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Card
                    number={values.number || ""}
                    name={values.name || ""}
                    expiry={values.expiry || ""}
                    cvc={values.cvc || ""}
                    focused={active}
                  />
                  <div>
                    <div
                      className="card space icon-relative"
                      style={{ width: "100%" }}
                    >
                      <label className="label">Card holder:</label>
                      <Field
                        name="name"
                        component="input"
                        type="text"
                        placeholder="Card Holder Name"
                        id="card_number"
                        className="input"
                        required
                      />
                      <i
                        className="fas fa-user"
                        style={{ color: "#AFAFAF" }}
                      ></i>
                    </div>
                    <span id="name_error" className="form_error"></span>
                  </div>
                  <div>
                    <div
                      className="card space icon-relative"
                      style={{ width: "100%" }}
                    >
                      <label className="label">Card number:</label>
                      <Field
                        name="number"
                        component="input"
                        type="text"
                        pattern="[\d| ]{16,22}"
                        placeholder="Card Number"
                        format={formatCreditCardNumber}
                        id="card_number"
                        className="input"
                        data-mask="0000 0000 0000 0000"
                        required
                      />
                      <i
                        className="far fa-credit-card"
                        style={{ color: "#AFAFAF" }}
                      ></i>
                    </div>
                    <span id="card_error" className="form_error"></span>
                  </div>
                  <div>
                    <div className="card-grp space">
                      <div className="card-item icon-relative">
                        <label className="label">Expiry date:</label>
                        <Field
                          name="expiry"
                          component="input"
                          type="text"
                          pattern="\d\d/\d\d"
                          format={formatExpirationDate}
                          id="card_expiry_date"
                          className="input"
                          data-mask="00 / 00"
                          placeholder="mm / yy"
                          required
                        />
                        <i
                          className="far fa-calendar-alt"
                          style={{ color: "#AFAFAF" }}
                        ></i>
                      </div>
                      <div className="card-item icon-relative">
                        <label className="label">CVC:</label>
                        <Field
                          name="cvc"
                          component="input"
                          type="text"
                          pattern="\d{3,4}"
                          placeholder="CVC"
                          format={formatCVC}
                          id="card_cvc"
                          className="input"
                          data-mask="000"
                          required
                        />
                        <i
                          className="fas fa-lock"
                          style={{ color: "#AFAFAF" }}
                        ></i>
                      </div>
                      <div
                        className="card-item icon-relative"
                        style={{ display: "none" }}
                      >
                        <label className="label">CVC:</label>
                        <input
                          id="order_id"
                          type="text"
                          className="input"
                          name="order_id"
                          placeholder="CVC"
                        />
                        <i
                          className="fas fa-lock"
                          style={{ color: "#AFAFAF" }}
                        ></i>
                      </div>
                      <div
                        className="card-item icon-relative"
                        style={{ display: "none" }}
                      >
                        <label className="label">CVC:</label>
                        <input
                          id="order_price"
                          type="text"
                          className="input"
                          name="order_price"
                          placeholder="CVC"
                        />
                        <i
                          className="fas fa-lock"
                          style={{ color: "#AFAFAF" }}
                        ></i>
                      </div>
                    </div>

                    <span id="exp_date_error" className="form_error"></span>
                    <br />
                    <span id="cvc_error" className="form_error"></span>
                  </div>
                  <div className="buttons">
                    <button type="submit" disabled={submitting}>
                      Pay £{price.price.final_price} By Card
                    </button>
                    {/* <button className="paypal-btn" onClick={handlePaypal}>
                      Pay £{price.price.final_price} With Paypal
                    </button> */}

                    {/* Form Default Reset Button  */}

                    <button
                      type="button"
                      onClick={form.reset}
                      disabled={submitting || pristine}
                    >
                      Reset
                    </button>

                    <br />
                  </div>
                  <div className="d-flex row justify-content-center align-items-center"></div>
                </form>
              );
            }}
          />
        </Styles>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Payment;

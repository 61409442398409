import React from "react";
import MainHeader from "../components/MainHeader";
import "./Page.css";
import logo from "../assets/logo.png";
import SocialLinks from "../components/SocialLinks/SocialLinks";
const Success = () => {
  return (
    <>
      <MainHeader />
      <SocialLinks />
      <section className="login-main-wrapper">
        <div className="main-container">
          <div className="login-process">
            <div className="login-main-container">
              <div className="thankyou-wrapper">
                <h2>
                  <img
                    src={logo}
                    alt="thanks"
                    style={{ height: "200px", width: "200px" }}
                  />
                </h2>
                <h1>Thank You</h1>
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "50px",
                    marginTop: "-20px",
                  }}
                >
                  ❤️❤️❤️
                </h4>
                <p>
                  Your Order Has Been Received You Should Receive Confirmation
                  Soon :)
                </p>
                <h5 style={{ textAlign: "center" }}>
                  <a href="/" className="website-link-b">
                    www.fabricdesigneroutlet.com
                  </a>
                </h5>
                <div className="clr"></div>
              </div>
              <div className="clr"></div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </section>
    </>
  );
};

export default Success;

import React from "react";
import Review from "./Review";
import './Feedback.css';
const Feedback = () => {
  return (
    <main>
      <section className="feedbackcontainer">
        <div children="title">
          <h2>Our Reviews</h2>
          <div className="underline"></div>
        </div>
        <Review />
      </section>
    </main>
  );
};

export default Feedback;

// import img1 from "./assets/Instagram/img1.png";
// import img2 from "./assets/Instagram/img2.png";
// import img3 from "./assets/Instagram/img3.png";

// import img1 from "./assets/img1.png";
// import img2 from "./assets/img2.png";
// import img3 from "./assets/img3.png";
// import img4 from "./assets/img4.png";
// import img5 from "./assets/img5.png";
// import img6 from "./assets/promo.jpeg";
// import img7 from "./assets/img7.png";

export const sliderItems = [
  {
    id: 1,
    img: "https://www.pngitem.com/pimgs/m/19-198197_happy-girl-shopping-png-transparent-png.png",
    title: "SUMMER SALE",
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "f5fafd",
  },
  {
    id: 2,
    img: "https://i.ibb.co/DG69bQ4/2.png",
    title: "AUTUMN COLLECTION",
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "fcf1ed",
  },
  {
    id: 3,
    img: "https://i.ibb.co/cXFnLLV/3.png",
    title: "LOUNGEWEAR LOVE",
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "fbf0f4",
  },
];
// export const HomeInstaSlider = [
//   {
//     id: 1,
//     img: img1,
//   },
//   {
//     id: 2,
//     img: img2,
//   },
//   {
//     id: 3,
//     img: img3,
//   },
// ];
export const categories = [
  // {
  //   id: 6,
  //   img: img6,
  //   category: "WATCHES",
  // },
  // {
  //   id: 1,
  //   img: img1,
  //   // img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  //   title: "MEN'S",
  //   category: "MEN",
  // },
  // {
  //   id: 2,
  //   img: img2,
  //   // img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  //   title: "WOMEN'S",
  //   category: "WOMEN",
  // },
  // {
  //   id: 3,
  //   img: img3,
  //   // img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //   title: "BAGS",
  //   category: "BAGS",
  // },
  // {
  //   id: 4,
  //   img: img4,
  //   // img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //   title: "SHOES",
  //   category: "SHOES",
  // },
  // {
  //   id: 5,
  //   img: img5,
  //   // img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //   title: "KIDS",
  //   category: "KIDS",
  // },
  // {
  //   id: 6,
  //   img: img6,
  //   // img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //   title: "WATCH",
  //   category: "WATCHES",
  // },
  // {
  //   id: 7,
  //   img: img7,
  //   // img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //   title: "ACCESSORIES",
  //   category: "ACCESSORIES",
  // },
];

export const popularProducts = [
  {
    id: 1,
    title: "Skin Shirt",
    desc: "Brand new product with user satisfaction",
    price: "£ 206",
    img: "https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
  },
  {
    id: 2,
    title: "Multi Shade Shirt",
    desc: "Brand new product with user satisfaction",
    price: "£ 150",
    img: "https://png.pngitem.com/pimgs/s/278-2787645_custom-hawaiian-shirts-polo-shirt-hd-png-download.png",
  },
  {
    id: 3,
    title: "Check Shirt",
    desc: "Brand new product with user satisfaction",
    price: "£ 206",
    img: "https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
  },
  {
    id: 4,
    title: "White women Shirt",
    desc: "Brand new product with user satisfaction",
    price: "£ 256",
    img: "https://www.burdastyle.com/pub/media/catalog/product/cache/7bd3727382ce0a860b68816435d76e26/107/BUS-PAT-BURTE-1320516/1170x1470_BS_2016_05_132_front.png",
  },
  {
    id: 5,
    title: "Hand bag",
    desc: "Brand new product with user satisfaction",
    price: "£ 556",
    img: "https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
  },
  {
    id: 6,
    title: "cap",
    desc: "Brand new product with user satisfaction",
    price: "£ 106",
    img: "https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
  },
  {
    id: 7,
    title: "Shoes",
    desc: "Brand new product with user satisfaction",
    price: "£ 56",
    img: "https://freepngimg.com/thumb/shoes/28116-8-nike-shoes-thumb.png",
  },
  {
    id: 8,
    title: "Overcoat",
    desc: "Brand new product with user satisfaction",
    price: "£ 1050",
    img: "https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
  },
];
export const women = [
  {
    navdata: "WOMEN'S COATS",
    index: 1,
    address: "WOMEN'S COATS",
    link: "women-coat",
  },
  {
    navdata: "WOMEN'S FASHION",
    index: 2,
    address: "WOMEN'S FASHION",
    link: "women-fashion",
  },
  {
    navdata: "WOMEN'S HOODIES",
    index: 3,
    address: "WOMEN'S HOODIES",
    link: "women-hoodies",
  },
  {
    navdata: "WOMEN'S T-SHIRT",
    index: 4,
    address: "WOMEN'S T-SHIRT",
    link: "women-t-shirt",
  },
];
export const kids = [
  {
    navdata: "KID'S TRACKSUIT",
    index: 1,
    address: "KID'S TRACKSUIT",
    link: "kid-track-suit",
  },
];
export const man = [
  { navdata: "COATS", index: 1, address: "MEN'S COATS", link: "men-coat" },
  { navdata: "GILET", index: 11, address: "MEN'S GILET", link: "men-gilet" },
  {
    navdata: "JACKETS",
    index: 2,
    address: "MEN'S JACKETS",
    link: "men-jackets",
  },
  {
    navdata: "HOODIES",
    index: 3,
    address: "MEN'S HOODIES",
    link: "men-hoodies",
  },
  {
    navdata: "SWEATSHIRTS",
    index: 5,
    address: "MEN'S SWEATSHIRTS",
    link: "men-sweatshirts",
  },
  {
    navdata: "PREMIUM GRADE T-SHIRTS",
    index: 6,
    address: "MEN'S PREMIUM GRADE T-SHIRTS",
    link: "men-premium-grade-t-shirts",
  },
  { navdata: "JUMPER", index: 7, address: "MEN'S JUMPER", link: "men-jumper" },
  {
    navdata: "JOGGERS",
    index: 8,
    address: "MEN'S JOGGERS",
    link: "men-joggers",
  },
  {
    navdata: "CLASSIC T-SHIRT",
    index: 9,
    address: "MEN'S CLASSIC T-SHIRT",
    link: "men-classic-t-shirt",
  },
  {
    navdata: "POLO SHIRT",
    index: 10,
    address: "MEN'S POLO SHIRT",
    link: "men-polo-shirt",
  },
  { navdata: "SHIRTS", index: 4, address: "SHIRTS", link: "men-shirts" },
  {
    navdata: "OVERSHIRTS",
    index: 14,
    address: "MEN'S OVERSHIRTS",
    link: "men-overshirts",
  },
  {
    navdata: "SWIM SHORTS",
    index: 15,
    address: "SWIM SHORTS",
    link: "men-swim-shorts",
  },
  { navdata: "SETS", index: 16, address: "SETS", link: "men-sets" },
];
export const bags = [
  { navdata: "PURSES", index: 1, address: "PURSES", link: "purses" },
  {
    navdata: "POUCHES & WALLETS",
    index: 2,
    address: "POUCHES & WALLETS",
    link: "pouches-and-wallets",
  },
  {
    navdata: "PREMIUM GRADE BAGS",
    index: 3,
    address: "PREMIUM GRADE BAGS",
    link: "premium-grade-bags",
  },
  { navdata: "BACKPACKS", index: 4, address: "BACKPACKS", link: "backpacks" },
  {
    navdata: "SUITCASES AND TRAVEL",
    index: 5,
    address: "SUITCASES AND TRAVEL",
    link: "suites-and-travel",
  },
  {
    navdata: "A+ GRADE BAGS",
    index: 6,
    address: "A+ GRADE BAGS",
    link: "a-grade-bags",
  },
];
export const accessories = [
  { navdata: "BATHROBES", index: 4, address: "BATHROBES", link: "bathrobes" },
  { navdata: "BOXERS", index: 1, address: "BOXERS", link: "boxers" },
  { navdata: "BRACELETS", index: 5, address: "BRACELETS", link: "bracelets" },
  { navdata: "EARRINGS", index: 6, address: "EARRINGS", link: "earrings" },
  { navdata: "CAPS", index: 3, address: "CAPS", link: "caps" },
  { navdata: "HATS", index: 7, address: "HATS", link: "hats" },
  { navdata: "NECKLACES", index: 8, address: "NECKLACES", link: "neckalces" },
  { navdata: "RINGS", index: 9, address: "RINGS", link: "rings" },
  { navdata: "SOCKS", index: 10, address: "SOCKS", link: "socks" },
  {
    navdata: "SUN GLASSES",
    index: 11,
    address: "SUN GLASSES",
    link: "sun-glasses",
  },
];
export const shoes = [
  {
    navdata: "WOMEN SNEAKERS",
    index: 1,
    address: "WOMEN'S SNEAKERS",
    link: "women-sneakers",
  },
  {
    navdata: "WOMEN BOOTS",
    index: 2,
    address: "WOMEN'S BOOTS",
    link: "women-boots",
  },
  {
    navdata: "WOMEN PLATFORM",
    index: 3,
    address: "WOMEN'S PLATFORM",
    link: "women-platform",
  },
  {
    navdata: "WOMEN HEELS",
    index: 4,
    address: "WOMEN'S HEELS",
    link: "women-heels",
  },
  {
    navdata: "WOMEN SLIDERS",
    index: 5,
    address: "WOMEN'S SLIDERS",
    link: "women-sliders",
  },
  {
    navdata: "WOMEN SANDALS",
    index: 6,
    address: "WOMEN'S SANDALS",
    link: "women-sandals",
  },
  {
    navdata: "MEN SNEAKERS",
    index: 7,
    address: "MEN'S SNEAKERS",
    link: "men-sneakers",
  },
  {
    navdata: "MEN SMART SHOES",
    index: 8,
    address: "MEN'S SMART SHOES",
    link: "men-smart-shoes",
  },
  { navdata: "MEN BOOT", index: 4, address: "MEN'S BOOT", link: "men-boot" },
  {
    navdata: "MEN TRAINER",
    index: 10,
    address: "MEN'S TRAINER",
    link: "men-trainer",
  },
  {
    navdata: "MEN CASUAL",
    index: 11,
    address: "MEN'S CASUAL",
    link: "men-casual",
  },
  { navdata: "UNISEX", index: 9, address: "UNISEX", link: "unisex" },
];
export const watches = [
  {
    navdata: "WOMEN WATCHES",
    index: 1,
    address: "WOMEN'S WATCHES",
    link: "women-watches",
  },
  {
    navdata: "MEN WATCHES",
    index: 2,
    address: "MEN'S WATCHES",
    link: "men-watches",
  },
];

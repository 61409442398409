import React, { useState } from "react";
import MainHeader from "../../components/MainHeader";
import "./Dashboard.css";
import UserInfo from "./UserInfo";
import UserOrders from "./UserOrders";
import { useSelector } from "react-redux";
const Dashboard = () => {
  const [showtype, setShowType] = useState("");
  const user = useSelector((state) => state.user.currentUser);
  return (
    <>
      <MainHeader />
      <div className="dashboard-container">
        <button
          className="dashboard-btn"
          onClick={(e) => setShowType("profile")}
        >
          User Profile
        </button>
        <button
          className="dashboard-btn"
          onClick={(e) => setShowType("orders")}
        >
          User Orders
        </button>
      </div>
      <div>
        {showtype === "profile" ? (
          <UserInfo />
        ) : showtype === "orders" ? (
          <UserOrders />
        ) : (
          <>
            <h1 style={{textAlign:'center', marginTop:'10px'}}>
              Welcome Back {user.first_name} {user.last_name}
            </h1>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
